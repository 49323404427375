.input_name {
    margin-bottom: 7px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 8px 10px 9px 135px;
    min-width: 288px;
    width: 100%;
    height: 100%;
    min-height: 55px;
    background: #FFFFFF;
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    border: 1.5px solid #EEEFEF;
}

.my_input:focus {
    outline: 1.5px solid #979797;
}

.error {
    border: 1.5px solid #F01818!important;
}

.error_message {
    color: #F01818;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
    margin-top: 8px;
}

.phoneInputWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.mySelect {
    position: absolute;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.selectedBlock {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-right: 10px
}


.mySelect img {
    margin-right: 5px;
}

.optionsWrapper {
    width: 315px;
    height: 256px;
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 59px;
    padding: 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    transition: all 0.3ms ease;
    z-index: 999999;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    margin-top: 25px;
    padding-top: 25px;
    height: 30px;
    background: #EBEBEB;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    margin-left: -30px;
}

.none {
    display: none;
}

.arrow {
    transform: rotate(0);
    transition: .3s all ease;
    margin: 0 15px 0 8px;
    width: 12px;
    height: 12px;
}

.arrowReverse {
    transform: rotate(180deg);
    transition: .3s all ease;
    margin: 0 15px 0 8px;
    width: 12px;
    height: 12px;
}

.option {
    width: auto;
    height: 25px;
    font-family: 'Cera Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
    align-items: center;
}

.countryName {
    display: flex;
    align-items: center;
}

.flag {
    width: 24px;
}

.selectedPhonecode {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}


@media only screen and (max-width: 640px) and (min-width: 370px) {

    .optionsWrapper {


    }


}
