.wrapper {
    border-radius: 12px;
    background: #FFF;
    width: 720px;
    box-sizing: border-box;
    padding: 24px 32px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    display: flex;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.flex {
    display: flex;
    /* align-items: flex-end; */
    gap: 24px;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown_block {
    width: 316px;
}

.amount_input {
    width: 208px;
    margin-right: 16px;
    position: relative;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.error_message_account {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
}

.amount_block {
    display: flex;
    align-items: flex-end;
}

.currency_input_block {
    width: 92px;
}

.button_wrapper {
    display: flex;
    justify-content: flex-end;
}

.button_block {
    width: 180px;
}

.info_text {
    color: #282828;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;

    margin-bottom: 32px;
}

.link {
    color: #4DB887;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.32px;
    position: relative;
}

.link::after {
    position: absolute;
    content: "";
    bottom: 2px;
    left: 0;
    right: 0;
    background-color: #4DB887;
    height: 1px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        border-radius: 12px;
        background: #FFF;
        width: 100%;
        box-sizing: border-box;
        padding: 32px 16px;
    }

    .flex {
        flex-direction: column;
    }

    .dropdown_block {
        width: 100%;
    }

    .amount_input {
        width: 100%;
        margin-right: 16px;
    }

    .amount_block {
        width: 100%;
    }

    .info_text {
        font-size: 14px;
    }

    .form {
        width: 100%;
    }
}
