.history_block {
    padding: 16px;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    border-radius: 6px;
    background: #FFF;
    margin-bottom: 16px;
}

.page_title {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.history_block_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.value_block {
    width: 92px;
}

.value_label {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-bottom: 6px;
}

.value {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0;
}
