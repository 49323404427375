.wrapper {
    width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
    box-sizing: border-box;
}

.main_container {
    height: 802px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #F4F4F4;
    position: relative;
    padding: 0 80px;
    z-index: 0;
}

.background_image {
    position: absolute;
    background-image: url("../../../assets/img/main_bg.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 839px;
    height: 802px;
    right: 0;
    z-index: 0;
}

.main_content_block {
    width: 582px;
    z-index: 1;
}

.title_block {
    color: #C8DBD2;
    font-family: Manrope, serif;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 86px;
}

.title_color {
    color: #0E492E;
}

.underline {
    text-decoration: underline rgba(57, 131, 97, 0.50);
}

.subtitle {
    color: #0E492E;
    text-align: justify;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 407px;
    margin-bottom: 32px;
}

.input_block {
    width: 408px;
    height: 56px;
    position: relative;
}

.error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
    left: 0;
}

.my_input {
    border-radius: 170px;
    border: 1.5px solid #E7E7E7;
    background: #FFF;
    outline: none;
    width: 100%;
    height: 56px;
    padding: 0 24px;
    box-sizing: border-box;
    transition: .5s all ease;
}

.my_input::placeholder {
    color: #E7E7E7;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.my_input:hover {
    border: 1.5px solid #B5B5B5;
    transition: .5s all ease;
}

.circle_btn {
    border-radius: 50%;
    transition: .5s all ease;
    width: 40px;
    height: 40px;
    border: 0;
    position: absolute;
    top: 8px;
    right: 8px;
    background: #0E492E url("../../../assets/img/arrow_btn.svg") no-repeat center;
    padding: 8px;
    box-sizing: border-box;
    cursor: pointer;
}

.circle_btn:hover {
    background-color: #4DB887;
    transition: .5s all ease;
}

.manage_block {
    padding: 120px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    gap: 64px;
}

.manage_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 32px;
}

.manage_text_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 704px;
}

.manage_subtitle {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 600px;
}

.blocks_wrapper {
    display: flex;
    gap: 24px;
}

.online_block {
    border-radius: 16px;
    background: #C8DBD2;
    padding: 40px;
    width: 410px;
}

.cards_block {
    border-radius: 16px;
    background: #E7F0EC;
    padding: 40px;
    width: 410px;
}

.online_bank_title {
    color: #282828;
    text-align: justify;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-top: 24px;
    margin-bottom: 22px;
}

.dedicated_wrapper {
    background-color: #F4F4F4;
}

.dedicated_block {
    background-image: url("../../../assets/img/dedicated_bg.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 588px;
    padding: 150px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dedicated_title {
    color: #FFF;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 115%;
    margin-bottom: 32px;
}

.dedicated_text {
    color: #FFF;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 72px;
    width: 700px;
}

.dedicated_button_block {
    width: 202px;
}

.dedicated_get_started_btn {
    width: 100%;
    padding: 16px 48px;
    border-radius: 120px;
    background: #398361;

    color: #FFF;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
    border: none;
    transition: .5s all ease;
    cursor: pointer;
}

.dedicated_get_started_btn:hover {
    background-color: #4DB887;
    transition: .5s all ease;
}

.open_account_block {
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 72px;
}

.open_account_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
}

.open_account_cards_block {
    display: flex;
    gap: 64px;
}

.open_account_card {
    border-radius: 32px;
    background: #FFF;
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 392px;
    box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.06), -10px 10px 40px 0px rgba(0, 0, 0, 0.06);
}

.icon {
    margin-bottom: 24px;
}

.card_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 24px;
}

.card_text_manage {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 56px;
}

.card_text {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 56px;
}

.additional_info_block {
    background-color: #F4F4F4;
    padding: 100px 0 100px 80px;
}

.map_block {
    position: relative;
    height: 465px;
    width: 100%;
    display: flex;
    align-items: center;
}

.map_block_title {
    color: #282828;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 72px;
    width: 506px;
    z-index: 1;
}

.map_background {
    position: absolute;
    background-image: url("../../../assets/img/dot_map.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 995px;
    height: 465px;
    right: -246px;
    z-index: 0;
}

.open_account_button_transparent {
    color: #4DB887;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;

    padding: 16px 48px;
    border-radius: 120px;
    border: 1.5px solid #C8DBD2;
    cursor: pointer;
    background: transparent;
    transition: .5s all ease;
}

.open_account_button_transparent:hover {
    border: 1.5px solid #E7E7E7;
    background: #E7E7E7;
    transition: .5s all ease;
}

.benefits_block {
    box-sizing: border-box;
    height: 640px;
    border-radius: 32px;
    background: linear-gradient(90deg, #C8DBD2 17.93%, #E7F0EC 84.65%);
    padding: 120px 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 120px;
}

.color_bg {
    background-color: #F4F4F4;
}

.benefits_blocks_wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 471px;
}

.benefit_small {
    padding: 39px 16px;
    border-radius: 16px;
    background: #FFF;
    width: 192px;
    box-sizing: border-box;
}

.benefit {
    padding: 39px 16px;
    border-radius: 16px;
    background: #FFF;
    width: 263px;
    box-sizing: border-box;
}

.benefit_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.benefit_text {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.benefits_description_title {
    margin-bottom: 32px;
}

.benefits_description {
    width: 438px;
    font-family: Manrope, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
}

.benefits_description_title_underline {
    text-decoration: underline #B5B5B5;
}

.benefits_description_title_color {
    color: #B5B5B5;
}

.benefits_description_subtitle {
    color: #282828;
    text-align: justify;
    font-family: Manrope, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.64px;
}

.protection_block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
    align-items: center;
}

.data_protection {
    display: flex;
    padding: 80px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 32px;
    background: #666;
    width: 388px;
    box-sizing: border-box;
}

.money_protection {
    display: flex;
    padding: 80px 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 32px;
    background: #313131;
    width: 388px;
    box-sizing: border-box;
}

.data_protection_title {
    color: #FFF;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.data_protection_text {
    color: #FFF;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.security_title {
    font-family: Manrope, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    width: 276px;
    text-align: center;
}

.request_card_link {
    color: #4DB887;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-decoration-line: underline;
    transition: .3s all ease;
}

.request_card_link:hover {
    color: #398361;
    transition: .3s all ease;
}

@media only screen
and (min-device-width: 475px)
and (max-device-width: 768px) {
    .background_image {
        width: 365px !important;
        height: 100%;
        right: -6% !important;
        background-image: url("../../../assets/img/mobile/main_bg_mobile.svg");
        background-size: auto;
    }

   
}
@media only screen
and (max-device-width: 768px) {
    .wrapper {
        width: auto;
        padding: 50px 20px;
    }
    .background_image {
        width: 100%;
        height: 100%;
        right: -23%;
        top: 49%;
        background-image: url("../../../assets/img/mobile/main_bg_mobile.svg");
        background-size: auto;
    }
    .main_container {
        justify-content: flex-start;
        padding: 33px 20px 30px;
    }
    .main_content_block {
        width: 100%;
    }
    .title_block {
        color: #C8DBD2;
        font-family: Manrope, serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        margin: 0 auto 35px;
        width: 255px;
        text-align: center;
    }

    .title_color {
        font-size: 32px;
        color: #0E492E;
        letter-spacing: 1px;
    }
    .subtitle {
        width: 100%;
        text-align: center;
        font-size: 20px;
        line-height: 26px;
    }
    .input_block {
        width: auto;
    }
    .manage_block {
        flex-direction: row;
        padding: 80px 20px;
        gap: 34px;
    }
    .manage_text_block {
        width: auto;
    }
    .manage_title, .dedicated_title, .open_account_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 14px;
    }
    .manage_subtitle {
        width: 100%;
        font-size: 20px;
    }
    .blocks_wrapper {
        flex-direction: column;
    }
    .online_block, .cards_block {
        width: auto;
    }
    .card_text {
        margin-bottom: 25px;
        font-size: 16px;
    }
    .online_bank_title {
        text-align: center;
    }
    .dedicated_block {
        width: 100%;
        padding: 50px 0;
        background-image: url("../../../assets/img/mobile/dedicated_bg_mobile.png");
    }
    .dedicated_text {
        font-size: 16px;
        width: 70%;
        line-height: 20px;
    }
    .open_account_block {
        padding: 70px 0;
        gap: 26px;
    }
    .open_account_cards_block {
        flex-direction: column;
    }
    .open_account_title {
        width: 240px;
    }
    .open_account_card {
        width: fit-content;
        margin: 0 20px;
    }
    .card_title {
        font-size: 26px;
    }
    .additional_info_block {
        padding: 60px 0 50px 20px;
    }
    .map_background {
        width: 100%;
        height: 100%;
        right: 0;
    }
    .map_block {
        flex-direction: column;
    }
    .map_block_title {
        width: 300px;
        margin: 250px auto 50px;
        font-size: 26px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
    }
    .benefits_block {
        flex-direction: column;
        height: fit-content;
        padding: 40px 20px;
        margin-bottom: 70px;
    }
    .benefits_blocks_wrapper {
        gap: 12px;
        width: fit-content;
    }
    .benefits_blocks_wrapper > div:nth-child(3) {
        margin-top: -36px;
    }
    .benefit_small {
        width: 160px;
        height: 122px;
        padding: 15px;
    }
    .benefit {
        width: 160px;
        height: 160px;
        padding: 15px;
    }
    .benefit_title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
    }
    .benefit_text {
        font-size: 12px;
        line-height: 18px;
    }
    .benefits_description {
        width: auto;
        margin-top: 30px;
    }
    .benefits_description_title, .benefits_description_title span {
        font-size: 32px;
        line-height: 36px;
        text-align: center;
    }
    .benefits_description_subtitle {
        margin: 0 30px;
        font-size: 20px;
        line-height: 26px;
        text-align: center;
    }
    .protection_block {
        flex-direction: column;
        padding: 0 20px 50px;
    }
    .security_title {
        margin: 50px auto;
    }
    .security_title span {
        font-size: 32px;
        line-height: 26px;
    }
}

@media only screen
and (max-device-width: 411px) {
    .benefits_blocks_wrapper {
        justify-content: center;
    }

    .security_title {
        width: 100%;
    }
}
