.desc_block {
    margin-bottom: 32px;
}

.desc_text {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.table_wrapper {
    border-radius: 12px;
    background-color: #fff;
    padding: 40px 32px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    max-width: 1115px;
}

.separate {
    margin-top: 32px;
    width: 720px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.form_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 48px;
}

.field_wrapper {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    width: 50%;
    max-width: 432px;
}

.input_block {
    width: 73%;
    position: relative;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sell_currency_block {
    width: 99px;
    height: 52px;
}

.converted_amount_block {
    color: #2A2E37;
    font-size: 16px;
    font-family: Manrope, sans-serif;
    line-height: 20px;
}

.statementTable thead > tr > th {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}


.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1px solid #E7E7E7 !important;
    cursor: pointer !important;
}

.tableCell {
    color: #2A2E37;
    text-align: right;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 18px;
}

.luxpayCell {
    color: #7DD9AF!important;
    text-align: right;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 18px;
}

.official_rate {
    margin-top: 24px;
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    padding-bottom: 138px;
}

.link {
    color: #4DB887;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.32px;
    position: relative;
}

.link::after {
    position: absolute;
    content: "";
    bottom: 2px;
    left: 0;
    right: 0;
    background-color: #4DB887;
    height: 1px;
}

.mobile_official_rate {
    display: none;
}

.button_wrapper {
    width: 184px;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.swipe {
    cursor: pointer;
    width: 24px;
    height: 24px;
    padding-top: 31px;
}

.swipe_button {
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 100%;
    height: 100%;
}

@media only screen
and (max-device-width: 768px) {
    .table_wrapper {
        border-radius: 12px;
        width: 100%;
        background-color: #fff;
        padding: 32px 16px;
    }

    .field_wrapper {
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    .form_block {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 48px;
    }

    .button_wrapper {
        width: 100%;
    }

    .official_rate {
        color: #282828;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;

        display: block;
        margin-top: 24px;
    }

    .desc_text {
        font-size: 14px;
    }

    .converted_amount_block {
        font-size: 14px;
    }

    .swipe {
        padding-top: 11px;
    }
}
