.form {
    padding: 25px 32px;
    background-color: #FFFFFF;
    border-radius: 12px;
    max-width: 720px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    flex-direction: column;
    gap: 24px;
    box-sizing: border-box;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.labelText {
    margin: 0 0 7px;
}

.periodRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.dropdown_wrapper {
    width: 316px;
}

.period, .account  {
    text-transform: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.purposeRow {
    display: flex;
    flex-direction: column;
}


.inputWrapper {
    width: 100%;
    position: relative;
}

.recipientAmount {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.transactionsBlock {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.checkboxWrapper {
    display: flex;
    gap: 32px;
    align-items: center;
}

.checkbox {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    align-items: center;
    padding: 8px 8px;
    border-radius: 4px;
}

.checkbox:hover {
    background: #E7E7E7;
}

.checkbox > span:first-child {
    width: 22px;
    height: 22px;
}

.checkbox > span:first-child > span {
    width: 22px;
    height: 22px;
}

.checkbox > span:first-child > span:after {
    left: 30%;
    top: 50%;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 8px;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.datepicker > div > input {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    color: #282828;
}

@media only screen
and (max-device-width: 768px) {
    .form {
        padding: 32px 16px;
    }

    .dropdown_wrapper {
        width: 100%;
    }

    .periodRow {
        flex-wrap: wrap;
        gap: 16px;
    }

    .datapicker_wrapper {
        width: 48%;
    }

    .checkboxWrapper {
        flex-wrap: wrap;
    }
}

@media only screen
and (max-device-width: 500px) {
    .datapicker_wrapper {
        width: 47%;
    }

    .checkboxWrapper {
        flex-wrap: wrap;
    }
}

@media only screen
and (max-device-width: 500px) {
    .form {
        padding: 32px 16px;
    }

    .dropdown_wrapper {
        width: 100%;
    }

    .periodRow {
        flex-wrap: wrap;
        gap: 16px;
    }

    .datapicker_wrapper {
        width: 46%;
    }

    .labelText {
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
    }

    .recipientAmount {
        flex-wrap: wrap;
    }

    .checkboxWrapper {
        gap: 0;
        margin-right: 24px;
        display: flex;
        flex-wrap: wrap;
    }

    .checkbox {
        
        font-weight: 600;
    }
    

    .headerWrapper {
        margin-bottom: 24px;
    }

    p {
        font-size: 14px;
    }

    span {
        font-size: 12px;
    }

    /* .buttonWrapper {
        width: 100%;
    } */
    
}

@media only screen
and (max-device-width: 436px) {
    .checkbox:last-child {
        margin-bottom: 0;
    }
}

@media only screen
and (max-device-width: 403px) {
    .checkbox:last-child {
        width: 106px;
        justify-content: right; 
    }
}