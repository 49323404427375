.wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #EEEFEF;
    background: #FFF;
    padding: 46px 16px;
    box-sizing: border-box;
    justify-content: space-between;
}

.right {
    display: flex;
    align-items: center;
    /* gap: 16px; */
    /* outline: 2px solid aqua; */
}

.login_wrapper {
    position: relative;
}

.login_header{
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
}

.userRound {
    width: max-content;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
    height: fit-content;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    justify-content: center;
    align-items: center;
    /* outline: 2px solid brown; */
}

.userRoundActive {
    width: max-content;
    height: fit-content;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
    position: absolute;
    top: 40px;
    z-index: 9;
    left: 94px;
    /* outline: 2px solid gray; */
}

.userRoundActive .user {
    background-color: #282828;
}

.userRoundActive .user_typography {
    color: #282828;
}

.always_show {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 36px; 
    /* фиксированная высота? */
    /* outline: 2px solid blue; */
}

.name {
    font-weight: 700;
    margin-bottom: 0;
    text-align: start;
}

.id {
    font-weight: 400;
    margin-bottom: 0;
    text-align: start;
}

.user {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/user.svg");
    top: 18px;
    right: 18px;
}

.userRound:hover .user_typography {
    transition: .3s all ease;
    color: #282828;
}

.openDetails {
    border-radius: 0 0 8px 8px;
    background: #FFF;
    height: fit-content;
    transition: .3s all ease;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 1;
    margin-top: 10px;
    /* outline: 2px solid red; */
}

.openDetailsHidden {
    width: 100%;
    height: 0;
    transition: .3s all ease;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -3;
}

.openDetailsHidden div {
    display: none;
    transition: .3s all ease;
}

.openDetails .user {
    background-color: #282828;
}

.checkbox_block {
    margin-bottom: 8px;
}

.inactive_notify {
    color: #F01818;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.flex_label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.account_type_label {
    color: #282828;
    text-align: left;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    word-break: keep-all;
}

.inactive_account {
    color: #B5B5B5;
}

.icon_plus {
    margin-right: 8px;
}

.text {
    margin-left: 8px;
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    transition: .3s all ease;
}

.logout_row {
    display: flex;
    align-items: center;
}

.logout_row:hover .text {
    transition: .3s all ease;
    color: #7DD9AF;
}

.img_logout {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/logout.svg");
}

.logout_row:hover .img_logout {
    transition: .3s all ease;
    background-color: #7DD9AF;
}

@media only screen
and (max-device-width: 768px) {

    .logo_login {
        position: absolute;
        width: 34px;
        height: 40px;
        top: 32px;
        left: 16px;
        z-index: 1;
    }

    

    .company_name {
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 127px;
        height: 13px;
    }


    .user_round {
        width: 60px;
        height: 60px;
        background: #EEEFEF;
        border: 1.5px solid #EEEFEF;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #979797;
        text-transform: uppercase;
    }

    .right {
        display: flex;
        align-items: center;
        /* gap: 16px; */
        width: 270px;
        /* outline: 2px solid aqua; */
    }

    .round {
        width: 60px;
        height: 60px;
        background: #FFFFFF;
        border: 1.5px solid #EEEFEF;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-right: 16px;
        /* outline: 1px solid black; */
    }

    .mail {
        transition: .3s all ease;
        width: 24px;
        height: 24px;
        background-color: #E7E7E7;
        display: block;
        mask-image: url("../../assets/img/personalAccount/header_message_icon.svg");
    }

    .round:hover .mail {
        transition: .3s all ease;
        background-color: #979797;
    }

    .left {
        display: flex;
        gap: 30px;
        align-items: flex-end;
    }

    .wrapper {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        padding: 40px 16px 32px 16px;
        box-sizing: border-box;
        justify-content: space-between;
    }

    .user {
        transition: .3s all ease;
        width: 24px;
        height: 24px;
        background-color: #E7E7E7;
        display: block;
        mask-image: url("../../assets/img/personalAccount/user.svg");
    }

    .user_typography {
        color: #B5B5B5;
        text-align: right;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: -0.24px;
    }

    .name span {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
        color: #282828;
    }

    .id {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
    }
}

@media only screen
and (max-device-width: 340px) {
    .right {
        width: 100%;
    }

    .round {
        width: 35px;
        height: 35px;
    }

    .userRound {
        width: 100%;
    }

    .name {
        font-size: 10px;
    }
    .id {
        font-size: 8px;
    }
}