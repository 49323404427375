.wrapper {
    display: flex;
    width: 720px;
    padding: 40px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.limit_block {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.title {
    color: #282828;
    font-family: Manrope, serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.line {
    position: relative;
}

.line_value {
    position: absolute;
    color: #FFF;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.button_block {
    width: 50%;
}

.modal_content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 88px;
}

.popup_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-top: 8px;
    margin-bottom: 24px;
}

.input_block {
    width: 100%;
    position: relative;
    margin-bottom: 24px;
}

.input_label {
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.input_label span {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #F01818;
    text-transform: uppercase;
    margin-right: 2px;
}

.input_error {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
    width: max-content;
}

.attached_file_wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
}

.invoice_doc_name {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin: 0 4px 0 8px;
}

.invoice_doc_name_error {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin: 0 4px 0 8px;
}

.error_text {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    padding-left: 45%;
}

.attached_file {
    display: flex;
    align-items: center;
}

.attached_file img {
    cursor: pointer;
}

.invoice_img {
    border-radius: 12px;
    background: #F6F6F6;
    padding: 16px;
}

.invoice_title {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 8px;
}

.invoice_desc {
    color: #101010;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.flex_start {
    align-items: flex-start;
}

.button_wrapper {
    width: 180px;
    position: relative;
}

.button_wrapper_add {
    width: 132px;
    position: relative;
}

.hidden_input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.flex_start {
    align-items: flex-start;
}

.add_dock_block {
    width: 100%;
}

.row_doc {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 16px;
    width: 100%;
    justify-content: space-between;
}

.button_row {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 48px;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.modal_text {
    text-align: center;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_success {
    color: #4DB887;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.modal_error {
    color: #F01818;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.modal_button {
    width: 80%;
}

.request_block {
    color: #FFA724;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom: 0;
    width: 93%;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        background: transparent;
        padding: 0;
        width: 100%;
    }

    .limit_block {
        border-radius: 6px;
        background: #FFF;
        padding: 24px 16px;
        margin-bottom: 0;
        width: 100%;
    }

    .title {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.4px;
    }

    .button_block {
        width: 100%;
    }

    .row_doc {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
    }
}
