.inputWrapper {
  position: relative;
}

.view {
  display: block;
}

.passwordView {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/img/no-view.svg) 0 0 no-repeat;
}

.passwordControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/img/view.svg) 0 0 no-repeat;
}

.my_input {
  width: 100%;
  height: 52px;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  border-radius: 4px;
  padding: 18px;
  box-sizing: border-box;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2A2E37;
  transition: border .3s;
}

.my_input::placeholder {
  color: #E7E7E7;
}

.my_input:focus {
  outline: 1.5px solid #979797;
}

.my_input:hover {
  border: 1px solid #979797;
}

.hidden {
  display: none;
}

.error_input {
  border: 1.5px solid #F01818!important;
}

.mobilePurpose {
  height: 70px;
}

@media only screen
and (max-device-width: 501px) {
.textareaStyles {
  padding: 17px 16px;
  align-items: flex-start;
  align-self: stretch;
  height: 95px;
}
}




