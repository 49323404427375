.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.form_wrapper {
    border-radius: 24px;
    background: #FFF;
    padding: 131px 110px 32px 110px;
    width: 315px;
}

.btn_block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 16px;
}

.btn_wrapper {
    width: 100%;
}

.forget_credentials {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-top: 16px;
    width: 338px;
}

.forget_credentials a {
    margin-left: 5px;
    text-decoration: underline;
}

.back {
    text-align: center;
    margin-top: 30px;

    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom: 133px;
}

.link {
    color: #4DB887;
    cursor: pointer;
}

.title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}
.subtitle_block {
    display: flex;
    justify-content: center;
}

.subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    width: 246px;
    margin-bottom: 48px;
}

.account_list_block {
    display: flex;
    flex-direction: column;
}

.chosen_account_wrapper {
    border-radius: 6px;
    border: 1px solid #4DB887;
    padding: 12px 24px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.account_wrapper {
    border-radius: 6px;
    border: 1px solid #E7E7E7;
    padding: 12px 24px;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
}

.acc_type {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-bottom: 8px;
}

.acc_type_inactive {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-bottom: 8px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}

.account_type_label {
    color: #313131;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.inactive_notification_block {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 7px;
}

.inactive_notification_block img {
    cursor: pointer;
}

.inactive_error_text {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.mail_link {
    color: #7DD9AF;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
}

.mail_link:hover {
    color: #7DD9AF;
}

.account_inactive {
    border: 1px solid #F01818;
}

.chosen_account_type_label {
    color: #282828;
}

.error_img {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
}

.modal_content {
    align-items: center;
    padding: 0 88px;
}

.error_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.mail_link {
    color: #7DD9AF;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    text-decoration-line: underline;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        height: auto;
    }
    .form_wrapper {
        padding: 40px 16px 16px 16px;
        border-radius: 8px;
        margin-bottom: 32px;
    }
    .back {
        margin-top: 32px;
        margin-bottom: 42px;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        letter-spacing: -0.28px;
    }
    .back .link {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        letter-spacing: -0.28px;
    }
}