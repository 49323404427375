.form {
    padding: 40px 32px;
    background-color: #FFFFFF;
    border-radius: 12px;
    max-width: 720px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.statementWrapper {
    display: none;
    align-items: center;
    justify-content: space-between;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    cursor: default;
    /* margin: 51px 0 31px; */
    max-width: 720px;
    height: 18px;
}

.statementWrapper.active {
    display: flex;
    margin-bottom: 15px;
}

.dropdown_wrapper {
    width: 316px;
}

.data_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}

.row {
    display: flex;
    gap: 24px;
}

.row span {
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #282828;
    text-transform: capitalize;
    min-width: 200px;
}

.downloadTitle {
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
}

.buttonsWrapper {
    display: flex;
    width: 173px;
    gap: 8px;
}

.buttonTitle {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    cursor: pointer;
    margin: 0;
    color: #4DB887;
    transition: .3s all ease;
}

.buttonTitle:hover {
    transition: .3s all ease;
    color: #7DD9AF;
}

.labelText {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.no_data_found {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
    text-transform: initial;
}

.popup_checkbox {
    margin-bottom: 48px;
}

.checkbox_content {
    display: flex;
    gap: 14px;
}

.info_circle {
    cursor: pointer;
}

@media only screen
and (max-device-width: 768px) {
    .row {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }

    .row span {
        text-align: left;
        min-width: initial;
    }

    .labelText {
        margin-bottom: 8px;
    }

    .dropdown_wrapper {
        width: 100%;
    }

    .form {
        padding: 32px 16px;
    }
}

@media only screen
and (max-device-width: 450px) { 
    .labelText {
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
        text-transform: uppercase;

    }

    span {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
    }

    b {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
    }
}