.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 80px;
}


.body {
    border-radius: 32px;
    background: #FFF;
    padding: 80px 160px 80px 80px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 120px;
}

.body_title {
    color: #282828;
    text-align: justify;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 48px;
    margin-top: 72px;
}

.body_title:first-child {
    margin-top: 0;
}

.subtitle {
    color: #282828;
    text-align: justify;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 40px;
}

.submittingParagraph {
    display: block;
    margin-bottom: 16px;
}

.text {
    width: 1096px;
    color: #282828;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 56px;
}




.markList {
    color: var(--v-30-neutral-black, #282828);
    text-align: justify;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
}

.markItem {
    margin-bottom: 22px;
}

p {
    margin-bottom: 32px;
}

ul {
    margin: 0;
}


.link_small {
    color: #4DB887;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
}

/* .consumerForm {
    color: #4DB887;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
} */

.definitionTitle {
    color: var(--v-30-neutral-black, #282828);
text-align: justify;


font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 26px; 
letter-spacing: -0.4px;

}

.definitonName {
    color: var(--v-30-neutral-black, #282828);
    text-align: justify;
    font-family: "Times New Roman";
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: 26px; /* 108.333% */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

.submitParagraph {
    display: block;
    margin-bottom: 16px;
}

.complaintForm {
    color: var(--v-30-neutral-black, #282828);
text-align: justify;


font-family: Manrope;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 30px; /* 115.385% */
letter-spacing: -0.52px;
}

.linkComplaintForm {
    color: var(--v-30-primary-light-green, #4DB887);

/* NEW by Liza/H4_600 */
font-family: Manrope;
font-size: 26px;
font-style: normal;
font-weight: 600;
line-height: 30px;
letter-spacing: -0.52px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
}



/* li {
    margin-bottom: 22px !important;
} */

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 16px;
    }
    .title {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 0;
    }
    .body_title:first-child {
        font-size: 26px;
        margin-bottom: 30px;
    }

    .body {
        padding: 24px 24px;
        margin-top: 32px;
        width: 100%;
    }
    .text, .link_small {
        font-size: 16px;
        margin-bottom: 24px;
        width: 100%;
    }
    p {
        width: 100%;
        font-size: 16px;
        margin-bottom: 16px;
    }

    li {
        margin-bottom: 0 !important;
    }
    .subtitle {
   
    text-align: justify;

    /* NEW by Liza/T2_600 */
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
    margin-bottom: 24px;
    }

    .submitParagraph {
        margin-bottom: 8px;
    }

    .markItem { 
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom: 0;
    }

    .definitionTitle {
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: -0.32px;
    }

    .definitonName {
        text-align: justify;
        font-family: "Times New Roman";
        font-size: 20px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px; /* 100% */
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        margin-bottom: 16px;
    }
    .submittingParagraph {
    color: var(--v-30-neutral-black, #282828);
    text-align: justify;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.32px;
    }

    .link_small {
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    word-wrap: normal;
    }

   

    /* .consumerForm {
        color: var(--v-30-primary-light-green, #4DB887);

/* NEW by Liza/T2_600 */
/* font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 20px;
letter-spacing: -0.32px;
text-decoration-line: underline;
text-decoration-style: solid;
text-decoration-skip-ink: none;
text-decoration-thickness: auto;
text-underline-offset: auto;
text-underline-position: from-font;
    } */ 

    .complaintForm {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
    }

    .linkComplaintForm {
        font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    }
}