.wrapper {
    padding-left: 112px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.wrapper_form {
    padding: 173px 110px 55px 110px;
    border-radius: 24px;
    background: #FFF;
}

.content {
    width: 315px;
}

.title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 64px;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
}

.input_block {
    margin-bottom: 16px;
    position: relative;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #F01818;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}


.btn_block {
    display: flex;
    justify-content: center;
}

.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 156px;
    height: 48px;
    box-sizing: border-box;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .3s all ease;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .3s all ease;
}

.back {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    color: #B5B5B5;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-left: 8px;
    text-decoration: none;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #F01818;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.password_rules {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    margin-top: 6px;
    margin-bottom: 20px;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_account {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.modal_icon {
    margin-bottom: 8px;
}

.modal_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
    margin-bottom: 16px;
}

.modal_subtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
    margin-bottom: 48px;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    /* margin-top: 60px; */
    width: 180px;
}

/* .btn_block {
    margin-top: 40px;
} */

.forget_credentials {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 16px;
    padding: 0 17px;
}

.forget_credentials a {
    margin-left: 5px;
    text-decoration: underline;
}

.subtitle_highlight {
    color: #FFA724;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.field_error {
    position: absolute;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    bottom: -18px;
    color: #F01818;
}

@media only screen
and (max-device-width: 999px) {
    .wrapper {
        padding-left: 0;
        margin-bottom: 56px;
    }
}

@media only screen
and (max-device-width: 768px) {

    .login_wrapper_content_left {
        position: relative;
        height: 100vh;
        width: 100%;
        background-image: url("../../assets/img/login_background.svg");
        background-size: 140%;
        display: flex;
        justify-content: flex-start;
        transition: .3s all ease;
        flex-direction: column-reverse;
    }

    .sign_in_wrapper {
        width: 100%;
        height: auto;
        padding: 40px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .sign_in_content {
        width: 100%;
    }

    .my_input {
        font-family: 'Century Gothic', serif;
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        padding: 14px 12px;
        box-sizing: border-box;
        outline: none;
        border: none;
        margin-top: 20px;
    }

    .subtitle {
        margin-bottom: 40px;
    }

    .logo_block {
        position: absolute;
        top: 20px;
        left: 16px;
    }
}


@media only screen
and (max-device-width: 550px) {

    .wrapper_form {
        padding: 40px 16px;
    }
    .content {
        width: 100%;
    }
}