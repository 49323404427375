.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 56px;
}

.cards_block {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.private_card {
    border-radius: 32px;
    background: #C8DBD2;
    padding: 104px 64px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.business_card {
    border-radius: 32px;
    background: #E7F0EC;
    padding: 104px 64px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card_title {
    color: #282828;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 40px;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 20px 100px;
        gap: 0;
    }
    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 32px;
    }
    .cards_block {
        flex-direction: column;
    }
    .card_title {
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0;
        margin-bottom: 32px;
    }
}