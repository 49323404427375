.my_btn {
    color: #FFF;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;

    padding: 8px 24px;
    border-radius: 120px;
    background: #0E492E;
    border: none;
    cursor: pointer;
    transition: .3s all ease;
}

.personal_btn {
    font-family: Manrope, sans-serif;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;

    display: flex;
    width: 100%;
    padding: 12px 24px 12px 20px;
    justify-content: center;
    align-items: center;

    border-radius: 5px;
    background: #4DB887;
    border: 1px solid #4DB887;
    cursor: pointer;
    transition: .3s all ease;
}

.my_btn:hover {
    background: #4DB887;
    transition: .3s all ease;
}

.personal_btn:hover {
    background: #7DD9AF;
    border: 1px solid #7DD9AF;
    transition: .3s all ease;
}

.img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.medium {
    height: 44px;
}

.large {
    padding: 16px 48px;
}

.small {
    height: fit-content;
}

.personal {
    display: flex;
    width: 180px;
    padding: 12px 24px 12px 20px;
    justify-content: center;
    align-items: center;
}

.confirm {
    background-color: #FFBB54;
    border: 1px solid #FFBB54;
}

.confirm:hover  {
    background-color:#FEC979;
}

.error{
    background-color: #F01818;
    border: 1px solid #F01818;
}

.error:hover  {
    background-color:#FF7366;
    border: 1px solid #FF7366;
}

.info {
    border-color: #FFA724 !important;
    color: #FFF !important;
    background: #FFA724;
}

.info:hover {
    color: #FFF !important;
    background: #FFC774;
}

.disabled,
.disabled:hover {
    background-color: #979797;
    border: 1px solid #979797;
    transition: background-color .3ms ease-in;
    cursor: progress;
}

.disabled.info,
.disabled:hover.info{
    background-color: #979797!important;
    border: 1px solid #979797!important;
    transition: background-color .3ms ease-in;
    cursor: progress;
}

@media only screen
and (min-device-width: 380px)
and (max-device-width: 768px) {
    .my_btn {
        font-size: 16px;
    }
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 379px) {
    .my_btn {
        font-size: 13px;
    }
}
