.wrapper {
    background: #F4F4F4;
    width: 100%;
    padding: 120px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 80px;
}

.subtitle {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px; /* 112.5% */
    letter-spacing: -0.64px;
    margin-bottom: 40px;
}
.box {
    display: flex;
    flex-direction: column;
    width: 680px;
    text-align: center;
}

.my_link {
    color: #4DB887;
    text-decoration-line: underline;
    cursor: pointer;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {

    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 30px;
    }
    .wrapper {
        padding: 33px 0px 106px;
    }

    .wrapper p {
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 115.385% */
        letter-spacing: -0.52px;
    }

    .wrapper span:last-of-type {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }
    .box {
        width: auto;
        padding: 0 30px;
    }
   
    .subtitle {
        font-size: 26px;
        line-height: 26px;
        margin-bottom: 20px;
    }

    .subtitle p:first-of-type {
        margin-bottom: 0;
    }

    .content_wrapper {
        max-width: 93%;
        margin: 0 auto;
    }
}