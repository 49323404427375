.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 80px;
}

.content {
    border-radius: 32px;
    background: #FFF;
    padding: 80px;
    width: 100%;
    box-sizing: border-box;
}

.content_title {
    color: #282828;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 48px;
}

.subtitle {
    color: #282828;
    text-align: justify;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 40px;
}

.text_block {
    margin-bottom: 48px;
    text-align: justify;
}

.text_block p {
    padding: 0;
    margin: 0 0 8px 0;
    color: #282828;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.text_block ul {
    list-style-type: none;
    margin-bottom: 8px;
}

.text_block li {
    padding: 0 0 8px 0;
    margin: 0;
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.text_block a {
    color: #4DB887;
    font-family: Manrope, sans-serif;
    font-weight: 600;
    text-decoration-line: underline;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 250px 20px 100px;
        gap: 0;
    }
    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 32px;
    }
    .content {
        padding: 30px 20px;
    }
    .content_title {
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .subtitle {
        font-size: 20px;
        letter-spacing: 0;
        margin-bottom: 20px;
        text-align: inherit;
    }
    .text_block {
        margin-bottom: 20px;
    }
    .text_block p, .text_block li {
        font-size: 16px;
        line-height: 20px;
    }
}
