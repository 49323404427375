.wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999999;
}

.content_wrapper {
    width: 544px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 12px;
    position: absolute;
    z-index: 1;
}

.notify_content_wrapper {
    width: 288px;
    padding: 32px;
    box-sizing: border-box;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 12px;
    position: absolute;
    z-index: 1;
}

.short_content {
    padding: 52px;
}

.content {
    padding: 64px 0;
    box-sizing: border-box;
}

.notify_content {
    padding: 0;
}

.infoModal::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: auto;
    height: auto;
    right: 8px;
    bottom: 8px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #FFA724;
    z-index: -1;
    box-sizing: border-box;
}

.confirmModal::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: auto;
    height: auto;
    right: 8px;
    bottom: 8px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #4DB887;
    z-index: -1;
}

.error::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: auto;
    height: auto;
    right: 8px;
    bottom: 8px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #F01818;
    z-index: -1;
}

contact_form::before {
    background-color: transparent;
}

.blur {
    backdrop-filter: blur(7px);
    background: rgba(0, 0, 0, 0.7);
}

@media only screen
and (max-device-width: 768px) {
    .content_wrapper {
        width: 100%;
        height: fit-content;
        max-width: 330px;
    }

    .content {
        padding: 48px 40px;
        box-sizing: border-box;
    }

    /*.confirmModal::before {*/
    /*    width: 96%;*/
    /*    height: 95%;*/
    /*}*/

    /*.infoModal::before {*/
    /*    width: 96%;*/
    /*    height: 95%;*/
    /*}*/
}
