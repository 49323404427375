.steps_block {
    width: 145px;
}

.motion_div {
    height: 100%;
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motion_div_scrolled {
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wrapper {
    background: #0E492E;
    position: relative;
}

.login_wrapper_body {
    display: flex;
}

.footer_text {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    padding-bottom: 24px;
    padding-top: 100px;
}

.licence_link {
    color: #4DB887;
}

.wrapper_bg {
    position: relative;
    background: url('../../assets/img/sign_in_bg.jpg');
    background-size: cover;
}

.login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
}

.content_left {
    width: 509px;
    height: 444px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 93px;
}

.welcome_block {
    color: #FFF;
    font-family: Manrope;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
}

.company_name {
    color: #4DB887;
}

.login_wrapper_content_right {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.logo_block a img {
    width: 382px;
    height: 120px;
}

.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.quest_block img {
    cursor: pointer;
}

.footer {
    position: absolute;
    height: 80px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px 31px;
    box-sizing: border-box;
}

.right_side {
    width: 832px;
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: flex-end;
}

.reg_number_link {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #F6F9FF;
    transition: .5s all ease;
    margin-bottom: 8px;
}

.reg_number_link:hover {
    color: #89C0E9;
    transition: .5s all ease;
}

.copyright {
    color: #DFDBDB;
}

.footer_item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 60px;
}

.subject_block {
    margin-bottom: 24px;
    position: relative;
}

.subject_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.message_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.button_block {
    width: 180px;
}

.error {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    border-bottom: 20px;
}


.popup_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.popup_subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
}

.flex_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex_modal img {
    margin-bottom: 8px;
}

.modal_text {
    text-align: center;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_error {
    color: #F01818;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

/* .modal_button {
    width: 80%;
} */

.welcome_block_mobile {
    display: none;
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
}

.popup_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.popup_subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
}

.flex_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex_modal img {
    margin-bottom: 8px;
}

.modal_text {
    text-align: center;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_error {
    color: #F01818;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; 
    letter-spacing: -0.28px;
}

/* .modal_button {
    width: 80%;
} */

@media only screen
and (max-device-width: 1000px) {
    .welcome_block_mobile {
        display: block;
        font-size: 36px;
        margin-bottom: 50px;
    }

    .welcome_block {
        display: none;
    }

    .wrapper_bg {
        position: initial;
        flex-direction: column;
        padding: 40px 16px;
        gap: 40px;
    }

    .welcome_block {
        display: none;
    }

    .wrapper {
        padding: 40px 16px;
    }

    .wrapper_bg {
        flex-direction: column;
        padding: 40px 16px;
        gap: 40px;
    }

    .logo_block a img {
        width: 200px;
        height: 66px;
    }

    .login_wrapper_body {
        
        flex-direction: column;
        
        gap: 40px;
    }

    

    .login_wrapper_content_left {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
        
        margin-bottom: 50px;
    }


    .login_wrapper_content_right {
        width: 100%;
        justify-content: center;
        /* margin-bottom: 56px; */
    }

    .content_left {
       height: 78px;
       width: 950px;
       align-items: center;
       margin-top: 0;
    }

    .quest_block {
        position: absolute;
        top: 36px;
        right: 16px;
    }

    .footer_text {
        position: unset;
        transform: none;
        left: 0;
        color: #398361;
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.24px;
        text-align: center;
        padding: 0px 16px;
    }
}

@media only screen
and (max-device-width: 1000px)
and (max-device-height: 700px) {
    .quest_block {
        position: absolute;
        top: 36px;
        right: 16px;
    }
}

@media only screen
and (max-device-width: 431px) {

    .login_wrapper_body {
        gap: 0;
    }

    .login_wrapper_content_left {
        margin-bottom: 40px;
    }

    .footer_text {
        text-align: left;
    }

    .welcome_block_mobile {
        font-size: 32px;    
    }

    .content_left {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: 0;
    } 
     .mobileLogo {
        height: 38px;
        padding: 0 !important;
    }

    .logo_block a img {
        width: 101px;
        height: 38px;
    }

    .mobileWithContent {
        width: 100%;
    }
}
