.accountOverviewWrapper {
    background-color: #FFFFFF;
    padding: 24px 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope', sans-serif;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.topMenu {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 34px;
    flex-direction: row-reverse;
}

.statement_block {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-direction: row-reverse;
}

.statement {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    color: #4DB887;
}

.statement:hover {
    cursor: pointer;
    color: #2046A1;
    transition: .3s all ease-in-out;
}

.statement_details {
    text-align: center;
    margin-bottom: 0;
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.topMenuTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
}

.topMenuButtons {
    display: flex;
    gap: 24px;
    align-items: center;
}

.arrow_img {
    width: 20px;
    height: 20px;
    transform: translateY(2px);
}

.accountTable thead > tr > th {
    color: #B5B5B5 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;

    font-family: Manrope, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.accountTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.accountTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.accountTable thead > tr > th > div > span {
    flex: initial !important;
}

.accountTable .ant-table-column-sorter {
    display: none !important;
}

.accountTable thead > tr > th > div {
    gap: 6px !important;
}

.accountTable thead > tr > th::before {
    content: none !important;
}

.accountTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 6px 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
    word-break: break-all;
}

.accountTable tbody > tr > td > p {
    margin: 0;
}

.accountTable tbody > tr > td:first-child,
.accountTable tbody > tr > td:last-child,
.accountTable thead > tr > th:last-child,
.accountTable thead > tr > th:first-child{
    padding-left: 6px !important;
    padding-right: 6px !important ;
}



.accountTitleWrapper {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    
}


.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    margin-top: -20px;
    margin-bottom: 8px;
}

.counterName {
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: -0.28px;
    color: #282828;
    text-transform: none;
}

.footerTotalCell {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 !important;
    border: none !important;
    height: 64px !important;
}

.footerTotalCell > p {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
    color: #282828;
    margin: 0;
}

.footerCell {
    padding: 0 !important;
    font-family: 'Manrope', sans-serif;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    border-bottom: none!important;
}


.modalContent {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.popup_info_circle {
    width: 32px;
    height: 32px;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitleSuccess {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 24px;
    margin-top: 68px;
    justify-content: center;
    width: 75%;
}

.buttonsWrapperSuccess {
    display: flex;
    gap: 24px;
    margin-top: 60px;
    justify-content: center;
}

.checkboxes {
    display: flex;
    font-weight: 500;
    gap: 47px;
    justify-content: center;
    flex-wrap: wrap;
}


.pending {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFBB54;
    margin: 0;
    text-transform: lowercase;
}

.blocked {  font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    color: #F01818;
    text-transform: lowercase;
}

.disabled {
    color: #979797;
    margin: 0;
}

.mobileRow {
    display: flex;
    gap: 24px;
    align-items: center;
}

.total_currency {
    margin: 0;
}

.button_block {
    width: fit-content;
}

.headerCell div {
    justify-content: flex-end !important;
}

.headerCell div{
    gap: 8px;
}

.headerCellNumber div {
    justify-content: flex-start !important;
}

.failed_typography {
    color: #F01818;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

@media only screen
and (max-device-width: 768px) {

    .topMenuTitle {
        font-size: 26px;
        line-height: 30px;
    }

    .accountOverviewWrapper {
        background-color: transparent;
        padding: 0;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        font-family: 'Manrope', sans-serif;
        box-shadow: none;
    }

    .topMenu {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 40px;
        width: 100%;
    }

    .topMenuButtons {
        flex-direction: column-reverse;
    }

    .mobileRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;
    }

    .button_block {
        width: 100%;
    }

    .buttonsWrapper {
        flex-direction: column;
        width: 100%;
    }

    .statement_block {
        flex-direction: column;
    }
}
