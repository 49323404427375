.steps_block {
    width: 145px;
}

.motion_div {
    height: 100%;
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motion_div_scrolled {
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wrapper {
    background: #0E492E;
    position: relative;
}

.wrapper_body {
    display: flex;
}

.footer_text {
    color: #398361;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;

    padding-top: 100px;
    padding-bottom: 24px;
}

.licence_link {
    color: #4DB887;
}

.wrapper_bg {
    background: url('../../assets/img/sign_in_bg.jpg');
    background-size: cover;
    min-height: 1024px;
}

.wrapper_bg_body {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.signup_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
}

.content_left {
    width: 509px;
    height: 444px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10%;
}

.welcome_block {
    color: #FFF;
    font-family: Manrope, sans-serif;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
}

.signup_wrapper_content_right {
    width: 50%;
}

.signup_wrapper_content_full {
    width: 100%;
    display: flex;
    justify-content: center;

    padding-top: 142px;
    padding-bottom: 100px;
}

.signup_wrapper_content_none {
    display: none;
}

.logo_block {
    width: 382px;
    height: 120px;
}

.logo_block a img {
    width: 100%;
    height: 100%;
}

.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.quest_block img {
    cursor: pointer;
}

.footer {
    position: absolute;
    height: 80px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px 31px;
    box-sizing: border-box;
}

.right_side {
    width: 832px;
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: flex-end;
}

.reg_number_link {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #F6F9FF;
    transition: .5s all ease;
    margin-bottom: 8px;
}

.reg_number_link:hover {
    color: #89C0E9;
    transition: .5s all ease;
}

.copyright {
    color: #DFDBDB;
}

.footer_item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 60px;
}

.subject_block {
    margin-bottom: 24px;
    position: relative;
}

.subject_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.message_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.button_block {
    width: 180px;
}

.error {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    border-bottom: 20px;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.main_info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 558px;
    margin-top: 10%;
}

.content {
    display: flex;
    padding: 113px 110px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    background: #FFF;
    height: 740px;
    box-sizing: border-box;
}

.content_info {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
}

.back {
    text-align: center;
}

.back_text {
    margin-right: 8px;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.back_link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.steps {
    margin-bottom: 32px;
    display: flex;
}

.welcome_block_mobile {
    display: none;
}

.backMobile {
    display: flex;
    margin-top: 40px;
    margin-bottom: 24px;
}

.popup_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.popup_subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
}

.flex_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex_modal img {
    margin-bottom: 8px;
}

.modal_text {
    text-align: center;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_error {
    color: #F01818;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; 
    letter-spacing: -0.28px;
}   

@media only screen
and (max-device-width: 768px) {

    .backDesktop {
        display: none;
    }

    .backMobile .back_text {
        margin-right: 4px;
        color: #B5B5B5;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
    }

    .backMobile .back_link {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
    }

    .wrapper_bg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background: url('../../assets/img/sign_in_bg.jpg');
        background-size: cover;
        height: 100%;
    }

    .wrapper {
        position: initial;
        height: 100%;
    }

    .signup_wrapper_content_left {
        position: relative;
        height: 78px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .content_left {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0px;
    }

    .welcome_block {
        display: none;
    }

    .welcome_block_mobile {
        display: block;
        color: #FFF;
        font-family: Manrope, sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 112.5% */
        letter-spacing: -0.64px;
    }

    .logo_block a img {
        width: 101px;
        height: 38px;
    }

    /* .signup_wrapper_content_left {
        display: none;
    } */

    .signup_wrapper_content_right {
        width: 100%;
        height: 100%;
    }

    .quest_block {
        position: absolute;
        top: 36px;
        right: 16px;
    }

    .main_info {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        max-width: 558px;
        margin-top: 0%;
    }

    .content {
        display: flex;
        padding: 40px 16px 16px 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 24px;
        background: #FFF;
        height: auto;
    }

    .steps {
        margin-bottom: 0px;
    }

    .footer_text {
        position: absolute;
        bottom: 24px;
        left: 16px;
        transform: none;
        color: #398361;
        text-align: left;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.24px;
        max-width: 279px;

        padding-top: 0px;
        padding-bottom: 0px;
    }

    .signup_wrapper_content_full {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .wrapper_body {
        height: 100%;
        flex-direction: column;
        gap: 40px;
        padding: 40px 16px 116px 16px;
    }
}



