.wrapper {
    max-width: 720px;
}

.autentication_list_block {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    box-sizing: border-box;
    padding: 40px 32px;
    margin-top: 36px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 92px;
}


.title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.settings_desc {
    margin-bottom: 32px;
}

.desc_item {
    margin-bottom: 12px;
}

.desc_item span {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;

}


.qr_image {
    border: 1.5px solid #EEEFEF;
    border-radius: 12px;
    width: 160px;
    height: 160px;
}

.qr_block {
    display: flex;
    align-items: center;
    height: fit-content;
    margin-bottom: 32px;
    gap: 24px;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #B5B5B5;
}

.input_wrapper {
    margin-top: 8px;
    width: 288px;
    position: relative;
}

.error {
    position: absolute;
    bottom: -20px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
}

.button_wrapper {
    width: 180px;
}

.modal_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
    margin-bottom: 20px;
}

.subtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
    margin-bottom: 60px;
}

.btn_block {
    display: flex;
    justify-content: center;
}

.btn_wrapper {
    width: 180px;
}

.input_block {
    margin-bottom: 32px;
}

.modal_content {
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        max-width: 100%;
    }

    .autentication_list_block {
        background: #FFFFFF;
        border-radius: 12px;
        width: 100%;
        box-sizing: border-box;
        padding: 32px 16px;
        margin-top: 36px;
    }

    .qr_block {
        flex-direction: column;
    }

    .mobile_width {
        width: 100%;
    }

    .input_wrapper {
        margin-top: 8px;
        width: 100%;
        position: relative;
    }

    .buttons_block {
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    .button_wrapper {
        width: 100%;
    }
}
