.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 56px;
}

.desc {
    width: 860px;
    color: #282828;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.body {
    border-radius: 32px;
    background: #FFF;
    padding: 80px 160px 80px 80px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 120px;
}

.subtitle {
    color: #282828;
    text-align: justify;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.text {
    color: #282828;
    text-align: justify;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 56px;
}

li {
    margin-bottom: 16px;
}

p {
    margin-bottom: 24px;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 20px 100px;
    }
    .main_subtitle {
        margin: 18px 40px;
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 1px;
    }
    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 24px;
    }
    .desc, .text, .text p {
        width: 100%;
        margin-bottom: 12px;
        font-size: 16px;
        line-height: 20px;
    }
    .body {
        padding: 30px 20px;
        margin-top: 70px;
    }
    .body_title {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 18px;
        margin-top: 18px;
    }
    .subtitle {
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0;
        margin-bottom: 10px;
    }
}