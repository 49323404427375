.verification_block {
    padding: 24px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    width: 720px;
    box-sizing: border-box;
}

.title {
    margin: 0 64px 0 24px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    word-break: keep-all;
}

.button_wrapper {
    width: 180px;
}

.questionnaire_list_block {
    padding: 40px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    box-sizing: border-box;
    
}

.questionnaire_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.table_header {
    display: flex;
    gap: 64px;
    justify-content: space-between;
    border-bottom: 1px solid #E7E7E7;
}

.header_value {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
    color: #B5B5B5;
    margin: 4px 0;
    display: flex;
    align-items: center;
}

.table_body {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.table_row {
    display: flex;
    gap: 12px;
    border-bottom: 1.5px solid #F6F9FF;
}

.row_value {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #979797;
    margin-top: 16px;
}

.verification_flex {
    display: flex;
    align-items: center;
}

.name {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
}

.rejected {
    color: #F01818;
}

.approved {
    color: #7DD9AF;
}

.incorrect {
    color: #FFA724;
}

.processed {
    color: #FFC774;
}

.notProceeded {
    color: #B5B5B5;
}

.flex {
    display: flex;
    align-items: center;
}

.gap {
    gap: 24px;
}

.edit {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/img/personalAccount/edit.svg");
    background-color: #EEEFEF;
    margin-right: 6px;
    cursor: pointer;

    display: none;
}

.edit_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/img/personalAccount/edit.svg");
    background-color: #FEC979;
    margin-right: 6px;
    cursor: pointer;
}

.edit_text_active {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FEC979;
}

.edit_text_inactive {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #EEEFEF;

    display: none;
}

.delete {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/img/personalAccount/delete.svg");
    background-color: #EEEFEF;
    margin-right: 6px;

    display: none;
}

.action {
    cursor: pointer;
}

.delete_text_active {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FF8A94;
}

.delete_text_inactive {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #EEEFEF;

    display: none;
}

.delete_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/img/personalAccount/delete.svg");
    background-color: #FF8A94;
    margin-right: 6px;
}

.questionnaire_table_mobile {
    display: none;
}

.reject_reason {
    color: #282828;
    text-align: right;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}

.capitalize {
    text-transform: capitalize;
}

.mail {
    color: #4DB887;
    text-decoration: underline;
}

.contact_block {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-top: 24px;
}

.reason_header {
    margin-top: 24px;
}

.reject_reason_block {
    margin-top: 24px;
}

.reason {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-top: 16px;
}

.info {
    cursor: pointer;
    margin-left: 8px;
}

@media only screen
and (max-device-width: 768px) {
    .questionnaire_list_block {
        width: 100%;
        padding: 24px 16px;
    }

    .questionnaire_table {
        display: block;
        border-radius: 8px;
        background: #FFF;
        padding: 16px;
        width: 100%;
        
    }

    .row_mobile {
        display: flex;
        justify-content: space-between;
        gap: 23px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEEFEF;
    }

    .block {
        width: 100%;
    }

    .label {
        color: #979797;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    .row_value {
        width: 100%;
        margin: 4px 0;
    }

    .action {
        width: 100%;
        cursor: pointer;
    }

    .name {
        margin-top: 0;
    }

    .verification_block {
        padding: 32px 16px;
        flex-direction: column;
        gap: 24px;
    }

    .button_wrapper {
        width: 100%;
    }

    .title {
        margin: 0 0 0 16px;
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2A2E37;
        word-break: keep-all;
    }

    .header_value {
        
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #979797;
        margin: 4px 0;
    }

   
}

@media only screen
and (max-device-width: 411px) {
    .table_header {
        gap: 30px;
    }

}
