.myModal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: none;
    background: rgba(0, 0, 0, 0.5);
    z-index: 3;
}

.myModal.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.myModalWrapper {
    padding: 7px;
    border-radius: 16px;
    background: white;

    min-width: 528px;
}

.myModalContent {
    padding: 80px 0px;
    background: white;
    border-radius: 15px;
    min-width: 528px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #FFA724;
}

.modal_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.modal_subtitle {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 316px;
    margin-bottom: 20px;
}

.input_block {
    width: 316px;
    margin-bottom: 60px;
    position: relative;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 384px;
}


@media only screen and (max-device-width: 768px) {
    .myModalWrapper {
        min-width: 90%;
        max-width: 330px;
        padding: 7px;
    }

    .myModalContent {
        min-width: 314px;
        padding: 40px;
        border-radius: 12px;
    }

    .modal_title {
        font-size: 22px;
        line-height: 26px;
        margin-bottom: 16px;

    }

    .modal_subtitle {
        font-size: 12px;
        line-height: 16px;
        width: 100%;
        margin-bottom: 16px;
    }

    .input_block {
        width: 100%;
        margin-bottom: 40px;
    }

    .buttonsWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 16px;
    }

    .buttonsWrapper button {
        width: 100%;
    }
}
