.footer_wrapper {
    padding: 48px 80px;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
}

.logo {
    mask-image: url("../../assets/img/logo.svg");
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    background-color: #0E492E;
    cursor: pointer;
    width: 185px;
    height: 69px;
    transition: .5s all ease;
}

.logo:hover {
    background-color: #4DB887;
    transition: .5s all ease;
}

.links_wrapper {
    display: flex;
    align-items: flex-start;
    gap: 104px;
}

.links_block {
    width: auto;
    display: flex;
    flex-direction: column;
}

.links_block_title {
    color: #0E492E;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 32px;
}

.footer_link {
    color: #0E492E;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 4px;
    transition: .5s all ease;
}

.footer_link:hover {
    color: #4DB887;
    transition: .5s all ease;
}

.social_networks {
    display: flex;
    gap: 16px;
    margin-bottom: 32px;
}

.linkedin {
    mask-image: url("../../assets/img/linkedin.svg");
    background-color: #0E492E;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: .5s all ease;
}

.linkedin:hover {
    background-color: #4CB686;
    transition: .5s all ease;
}

.links_block_title_follow {
    color: #0E492E;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.copyright {
    display: block;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 140% */
    letter-spacing: -0.2px;
}

.copyright_mobile {
    display: none;
}

.license_link {
    color: #4DB887;
    text-decoration-line: underline;
    cursor: pointer;
}

.button_block_mobile {
    display: none;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .footer_wrapper {
        flex-direction: column;
        padding: 70px 40px;
    }
    .links_wrapper {
        flex-direction: column;
        gap: 24px;
    }
    .copyright {
        display: none;
    }
    .copyright_mobile {
        display: block;
        color: #B5B5B5;
        font-family: Manrope, sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.2px;
        margin-top: 5px;
        margin-bottom: 32px;
    }
    .links_block_title {
        margin-bottom: 20px;
        color: #0E492E;
    }
    .footer_link {
        color: #0E492E;
    }
    .linkedin, .facebook, .twitter {
        background-color: #0E492E;
    }
    .button_block_mobile {
        display: block;
        width: 202px;
        margin-top: 40px;
    }
    .get_started_btn {
        width: 100%;
        padding: 16px 48px;
        border-radius: 120px;
        background: #0E492E;

        color: #FFF;
        font-family: Manrope, sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.4px;
        border: none;
        transition: .5s all ease;
        cursor: pointer;
    }
}
