.ant-switch.ant-switch-checked {
    background: #7DD9AF;
}

.ant-switch.ant-switch-checked:hover {
    background: #7DD9AF!important;
}

.ant-switch.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 24px);
}

.ant-switch.ant-switch-checked .ant-switch-handle::before {
    background: #FFF;
}

.ant-switch .ant-switch-handle::before {
    background: #E7E7E7;
}

@media only screen
and (max-device-width: 768px) {
    .ant-switch.ant-switch-checked .ant-switch-handle {
        inset-inline-start: calc(100% - 22px);
    }
}
