.input_wrapper {
    margin-bottom: 8px;
    position: relative;
}

.input_label {
    color: #B5B5B5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.error_text {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
    left: 0;
}

.popup_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 88px;
}

.input_wrapper {
    width: 100%;
    margin-bottom: 24px;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_title_create_account {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 48px;
}

.button_block {
    
}

.contacts_button_wrapper {
    margin-top: 48px;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.flex_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.modal_text {
    text-align: center;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.modal_success {
    color: #4DB887;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.modal_error {
    color: #F01818;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px; 
    letter-spacing: -0.28px;
}

.modal_button {
    width: 80%;
}

@media only screen
and (max-device-width: 768px) {
    .flex {
        flex-direction: column;
    }

    .input_wrapper {
        width: 100%;
    }

    .contacts_button_wrapper {
        width: 100%;
    }

    .block {
        padding: 66px 24px;
        min-height: initial;
    }
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .consultation {
        position: absolute;
        top: 50px;
        bottom: 0;
        right: 0;
        left: -134px;
        width: 332px;
        height: 81px;
        z-index: -1;
    }
    .input_wrapper {
        margin-bottom: 15px;
    }

    .decorated {
        display: initial;
        height: 24px;
    }
    .block {
        padding: 30px 0px;
    }
}
