.wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #EEEFEF;
    background: #FFF;
    padding: 12px 16px;
    box-sizing: border-box;
    justify-content: space-between;
}

.right {
    display: flex;
    align-items: center;
    gap: 16px;
}

.round {
    width: max-content;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
    height: fit-content;
    background: #FFFFFF;
    border: 1.5px solid #E7E7E7;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transition: .3s all ease;
    flex-direction: row-reverse;
}

.user_typography {
    color: #B5B5B5;
    transition: .3s all ease;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: -0.24px;
}

.round:hover .user_typography {
    transition: .3s all ease;
    color: #282828;
}

.round .name, .id {
    margin-bottom: 0;
}

.user {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/user.svg");
    top: 18px;
    right: 18px;
    transition: .3s all ease;
}

.round:hover .user {
    transition: .3s all ease;
    background-color: #282828;
}


@media only screen and (min-device-width: 360px) {
    .buttons_block {
        display: flex;
        gap: 16px;
    }
}

@media only screen and (min-device-width: 500px) {
    .button_wrapper_short {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
    letter-spacing: -0.24px;
    }
}

@media only screen and (max-device-width: 359px) {
    .buttons_block {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}




