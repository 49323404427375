.wrapper {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
}

.small_elipse_container {
    position: absolute;
    transform: rotate(180deg);
    left: 0;
    top: 200px;
    z-index: 1;
    width: 247px;
    
}

.small_elipse_container img {
    width: 297px;
    
}

.high_elipse_container {
    position: absolute;
    right: 0;
    top: 12%;
    width: 587px;
}

.high_elipse_container img {
    width: 587px;
}

.container {
    width: 1440px;
    padding: 0 80px;
}

.desc_block {
    margin: 120px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
}

.title_block {
    font-family: Manrope, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    text-align: center;
    margin-bottom: 56px;
}
.title {
    width: 960px;
    text-align: center;

    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
}

.underline {
    color: #398361;
    text-decoration: underline #C8DBD2;

    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px; /* 112.5% */
    letter-spacing: -0.96px;
}

.points_block p{
    text-align: center;
    margin-bottom: 20px;
}

.coming_soon {
    color: #4DB887;
}

.description {
    color: #282828;
    text-align: justify;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 911px;
}

.button_wrapper {
    margin-top: 72px;
}

.table_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 120px;
}

.header {
    border-radius: 220px;
    background: #282828;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 80px;
    box-sizing: border-box;

    color: #FFF;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.body {
    border-radius: 32px;
    background: #FFF;
    box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.06), -4px 0 24px 0 rgba(0, 0, 0, 0.06);
    padding: 80px 70px 80px 80px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 32px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.row_text {
    width: 270px;
    color: #282828;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.row_value {
    width: 150px;
    text-align: left;

    color: #282828;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.color_value {
    color: #B5B5B5;
}

.additional_price {
    color: #282828;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 16px 88px;
    }

    .small_elipse_container {
        top: 130px;
        width: 125px;
    }

    .small_elipse_container img {
        width: 166px;
    }

    .high_elipse_container {
        top: 13%;
        width: 199px;
    }

    .high_elipse_container img {
        width: 354px;
    }
    .title_block {
        padding: 0 25px;
        font-size: 32px;
        line-height: 36px;
    }
    .title {
        width: auto;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: -0.64px;
    }
    .description {
        width: 80%;
        padding: 0 20px;
        font-size: 20px;
        line-height: 26px;
    }
    .button_wrapper {
        margin-top: 32px;
    }
    .underline {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 112.5% */
        letter-spacing: -0.64px;
    }
    .table_block {
        align-items: center;
        margin-bottom: 24px;
        margin-top: 70px;
    }
    .points_block p{
        margin-bottom: 0px;
    }
    .coming_soon {
        font-size: 20px;
    }
    .header {
        width: 100%;
        padding: 16px;
    }
    .header span {
        font-size: 16px;
        color: #FFF;
    }
    .row_value {
        width: 100px;
        text-align: start;
        font-size: 16px;
    }
    .body {
        padding: 30px 20px;
        gap: 14px;
    }
    .row_text {
        width: 170px;
        text-align: start;
        font-size: 16px;
    }
    .desc_block {
       margin: 0;
    }
}
