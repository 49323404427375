.info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-direction: column;
}

.title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    letter-spacing: -0.52px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    quotes: 100%;
}

.input_label {
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; 
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.back {
    text-align: center;
}

.back_text {
    margin-right: 8px;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: -0.32px;
}

.back_link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; 
    letter-spacing: -0.32px;
}

.error {
    color: #F01818;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; 
}

.button_group {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkboxes {
    margin-top: 37px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkbox {
    display: flex;
    gap: 8px;
    align-items: center;
}

.checkbox_text {
    color: #282828;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; 
    letter-spacing: -0.28px;
}

.link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    margin-left: 4px;
}