.wrapper {
    border-radius: 12px;
    background: #FFF;
    box-sizing: border-box;
    width: 100%;
    padding: 24px 32px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.title {
    margin-bottom: 48px;
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.buttonsWrapper {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
}

.button_block {
    width: 180px;
}


.statementTable thead > tr > th {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}


.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1px solid #E7E7E7 !important;
    cursor: pointer !important;
}

.tableCell {
    color: #2A2E37;
    text-align: right;
    font-size: 14px;
    font-family: Manrope, sans-serif;
    line-height: 18px;
}

.ratio_cell {
    padding: 0;
    margin: 0;
}

.short {
    width: 208px!important;
}

.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-size: 24px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-size: 16px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.circle {
    width: 32px;
    height: 32px;
    display: block;
}

.popup_button_block {
    width: 180px;
    margin: 0 auto;
}

.transfer_card_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .statementTable {
        display: none;
    }

    .transfer_card_mobile {
        display: flex;
        margin-top: 48px;
        flex-direction: column;
        gap: 24px;
    }

    .mobile_row {
        display: flex;
        gap: 48px;
    }

    .transfer_label {
        color: #979797;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    .transfer_value {
        color: #2A2E37;
        font-family: Manrope, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
    }

    .buttonsWrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 48px;
        gap: 16px;
    }

    .button_block {
        width: 100%;
    }
}
