.coming_soon {
    font-family: Manrope, serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-top: 40px;
    color: #282828;
}
