.wrap_marq {
    min-width: 100%;
    height: 48px;
    text-decoration: none;
}

.items_wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 40px;
    background-color: #313131;
}

.items_wrap:before,
.items_wrap:after {
    content: "";
    height: 100%;
    top: 0;
    width: 10%;
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.items {
    flex-shrink: 0;
    outline: none;
    display: flex;
    align-items: center;
    counter-reset: item;
    min-width: 100%;
    background-color: #313131;
    height: 48px;
    gap: 40px;
}

.item {
    font-family: Manrope;
    flex: 0 0 auto;
    counter-increment: item;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.1s ease-in-out;
}

.wrap_marq:hover .item {
    color: #B5B5B5;
}

.wrap_marq:focus .item {
    color: #B5B5B5;
}

.item:before {
    content: "Stay Vigilant Against Fraudulent Messages";
}

.marquee {
    animation: scroll 60s linear infinite;
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 40px));
    }
}

@media only screen
and (max-device-width: 768px) {
    .wrap_marq {
        height: 40px;
    }
    .marquee {
        animation: scroll 70s linear infinite;
    }
    .item {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 1.2px;
        margin-right: 32px;
    }
}
