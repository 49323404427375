.wrapper {
    background: #F4F4F4;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 80px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.form_wrapper {
    width: 60%;
    padding: 80px 64px;
    box-sizing: border-box;
    border-radius: 32px;
    background: #FFF;
    box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.08), -4px 0 20px 0 rgba(0, 0, 0, 0.08);
}

.bg {
    border-radius: 32px;
    height: 780px;
}

.bg img{
    width: 100%;
    height: 780px;
    border-radius: 32px;
}

.title {
    color: #398361;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 24px;
}

.subtitle {
    color: #282828;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 48px;
    width: 425px;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        margin-top: 0;
        padding: 48px 30px;
        flex-direction: column;
        gap: 22px;
    }
    .form_wrapper {
        height: fit-content;
        width: 100%;
        padding: 20px;
    }
    .title {
        font-size: 36px;
        margin-bottom: 10px;
    }
    .subtitle {
        font-size: 20px;
        width: 100%;
        margin-bottom: 0;
    }
    .bg {
        height: fit-content;
        /* width: 100%; */
    }
    .bg img {
        width: 100%;
        /* height: 570px; */
    }
}
