.templateWrapper {
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
    background-color: white;
    font-family: Manrope, sans-serif;
    color: #2A2E37;
    width: 720px;
    align-items: flex-start;
    gap: 32px;
    border-radius: 12px;
    margin-top: 64px;
    margin-bottom: 66px;
    box-sizing: border-box;
}

.title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    width: 100%;
    margin: 0 0 24px 0;
    font-family: 'Manrope', sans-serif;
}

.detailsWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.row {
    display: flex;
    gap: 8px;
    align-items: flex-start;
}

.inputsWrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    gap: 24px;
    margin: 0;
}

.detailsWrapperItemName {
    color: #B5B5B5;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.textWrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 260px;
    overflow: hidden;
    margin-bottom: 0;
}

.buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.detailsWrapperItem {
    position: relative;
    width: 100%;
}

.error_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.payer_details {
    width: 100%;
}

@media only screen
and (max-device-width: 768px) {
    .inputsWrapper {
        flex-direction: column;
    }

    .templateWrapper {
        display: flex;
        flex-direction: column;
        padding: 48px 16px;
        width: 100%;
        gap: 48px;
        border-radius: 12px;
        margin-top: 0;
    }
}
