.input_block {
    width: 317px;
    position: relative;
}

.textarea_block {
    width: 100%;
    position: relative;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #B5B5B5;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.input_label img {
    cursor: pointer;
}

.color_popover {
    color: #89C0E9;
}

.block {
    margin-bottom: 32px;
}

.row {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.form_header {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 32px;
}

.row_doc {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.input_block div.flex_label {
    gap: 24px;
}

.add_dock_block {
    margin-top: 32px;
}

.attached_file_wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
}

.invoice_doc_name {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.attached_file {
    display: flex;
    align-items: center;
    gap: 8px;
}

.attached_file img {
    cursor: pointer;
}

.title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 24px;
}

.languages_dropdown {
    padding-top: 24px;
    width: 92px;
}

.currency_dropdown {
    width: 92px;
}

.short {
    width: 208px;
}

.input_block_fixed {
    width: 316px;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_payment_active {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.type_of_payment_inactive {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.continue_block {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

.button_wrapper {
    width: 180px;
    position: relative;
}

.hidden_input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.required {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2A2E37;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.mobile_row {
    display: flex;
    gap: 16px;
    justify-content: start;
    align-items: center;
}

.invoice_img {
    border-radius: 12px;
    background: #EEEFEF;
    padding: 16px;
}

.invoice_title {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    margin-bottom: 6px;
}

.invoice_desc {
    color: #101010;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.flex_start {
    align-items: flex-start;
}

.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.error {
    color: #F01818;
    font-weight: 800;
}

.popup_subtitle {
    font-family: Manrope, sans-serif;
    color: #B5B5B5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
}

.popup_button_block {
    width: 180px;
    margin: 0 auto;
}

.red {
    color: #F01818;
}

.rowWrapperElements {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen
and (max-device-width: 768px) {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
    }

    .row_doc {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
    }

    .input_block_fixed {
        width: 100%;
    }

    .mobile_row {
        display: flex;
        gap: 8px;
        width: 100%;
        flex-direction: row;
    }

    .short {
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    
}

@media only screen
and (min-device-width: 501px) {
    
    .rowWrapperElements {
        display: grid;
        grid-template-areas:
        "wallet purpose"
        "amount listOfAcc";
        grid-template-columns: 1fr 1fr; /* Два столбца */
        gap: 20px; /* Отступы между элементами */
    }

    .mobileOrderAmount {
        grid-area: wallet;
    }

    .mobileOrderPurpose {
        grid-area: purpose;
      }
    
      .mobileOrderAmount {
        grid-area: amount;
      }

    .showForDesk {
        grid-area: listOfAcc;
    }

    



}
@media only screen
and (max-device-width: 501px) {
    .form_header {
        display: block;
    }

    .mobileMargin {
        margin-bottom: 32px;
    }

    .red {
        font-size: 14px;
    }

    .title_label {
        font-family: Manrope;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
        text-transform: uppercase;
    }

    .file_wrapper {
        display: block;
    }

    .continue_block {
        display: block;
    }

    .required {
        margin-bottom: 16px;
    }

    .attached_file_wrapper {
        display: block;
    }

    .button_wrapper {
        width: 100%;
    }

    .currency_dropdown {
        padding-top: 20px;
    }
    .currency_mobile_hidden {
        display: none;
    }

    .mobileOrderAmount {
        margin-top: 8px;
    }

    .mobileOrderPurpose {
        margin-top: 8px;
    }
}
