.wrapper_mobile {
    display: none;
}

.wrapper_desktop {
    display: block;
}

.logo {
    margin-bottom: 64px;
    float: left;
}

.links_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #398361;
    margin-bottom: 20px;
    text-align: left;
}

.services_block {
    margin-top: 56px;
}

.nav_item {
    display: flex;
    width: 100%;
    height: 24px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;
}

.logout {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    margin-left: 16px;
}

.left {
    color: #000;
    display: flex;
    align-items: center;
}

.icons_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.right {
    color: #000;
    display: flex;
    align-items: center;
    position: relative;
    width: 60px;
    height: 60px;
}

.round {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: .3s all ease;
}

.userRound {
    width: max-content;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
    height: fit-content;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    justify-content: center;
    align-items: center;
}

.userRoundActive {
    width: max-content;
    height: fit-content;
    border: 1px solid #E7E7E7;
    background: #FFFFFF;
    border-radius: 24px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
}

.userRoundActive .user {
    background-color: #282828;
}

.userRoundActive .user_typography {
    color: #282828;
}

.openDetails {
    border-radius: 0 0 8px 8px;
    background: #FFF;
    height: fit-content;
    transition: .3s all ease;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 1;
    margin-top: 10px;
}

.openDetailsHidden {
    width: 100%;
    height: 0;
    transition: .3s all ease;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -3;
}

.openDetailsHidden div {
    display: none;
    transition: .3s all ease;
}

.always_show {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 100%;
}

.icon_point {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
}

.chat {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/header_chat_icon.svg");
}

.round:hover .chat {
    transition: .3s all ease;
    background-color: #282828;
}

.mail {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/header_message_icon.svg");
}

.user {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/user.svg");
    top: 18px;
    right: 18px;
}

.round:hover .mail {
    transition: .3s all ease;
    background-color: #282828;
}

.userRound:hover .user {
    transition: .3s all ease;
    background-color: #282828;
}

.userRound:hover .user_typography {
    transition: .3s all ease;
    color: #282828;
}

.openDetails .user {
    background-color: #282828;
}

.dropdown_language {
    display: flex;
    align-items: center;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #131313;
    cursor: pointer;
    margin-right: 20px;
}

.welcome_block {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
}

.caps {
    text-transform: uppercase;
}

.chat_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
}

.btn_header {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #BCBEC3;
    cursor: pointer;
}

.btn_block {
    display: flex;
    align-items: center;
    padding: 6px 0;
}


.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 60px;
}

.subject_block {
    margin-bottom: 24px;
    position: relative;
    width: 100%;
}

.subject_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.subject_block div {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.message_block span {
    color: #979797;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}



.buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.modal_subtitle {

}

.button_block {
    width: 180px;
}

.error {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    border-bottom: 20px;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrapper_mobile {
    display: none;
}

.test_grid {
    background: #F6F6F6;
    display: flex;
}

.side_menu {
    background: #0E492E;
    width: 283px;
    position: static;
    margin-right: -32px;

}

.main_content {
    background: #F6F6F6;
    padding-right: 32px;
    border-radius: 32px 32px 32px 32px;
    padding-left: 32px;
    width: 85%;
    min-height: 100vh;
}

.main_content.validDate {
    opacity: 1;
}

.fixed {
    position: fixed;
    padding: 64px 32px;
    box-sizing: border-box;
    height: 100vh;
    top: 0;
}

.header {
    display: flex;
    align-items: center;
    margin-top: 64px;
    justify-content: space-between;
}

.user_info_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.user_typography {
    color: #B5B5B5;
    text-align: right;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.24px;
    transition: .3s all ease;
}

.name {
    font-weight: 700;
    margin-bottom: 0;
    text-align: start;
}

.id {
    font-weight: 400;
    margin-bottom: 0;
    text-align: start;
}

.flex_label {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.icon_plus {
    margin-right: 8px;
}

.account_type_label {
    color: #282828;
    text-align: left;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    word-break: keep-all;
}

.checkbox_block {
    margin-bottom: 8px;
}

.logout_block {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid #E7E7E7;
}

.text {
    margin-left: 8px;
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    transition: .3s all ease;
}

.img_logout {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/logout.svg");
}

.logout_row {
    display: flex;
    align-items: center;
}

.logout_row:hover .text {
    transition: .3s all ease;
    color: #7DD9AF;
}

.logout_row:hover .img_logout {
    transition: .3s all ease;
    background-color: #7DD9AF;
}

.contact_form {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkboxes {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.checkbox {
    display: flex;
    gap: 8px;
    align-items: center;
}

.checkbox_text {
    color: #282828;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    margin-left: 4px;
}

.inactive_notify {
    color: #F01818;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.inactive_account {
    color: #B5B5B5;
}

.main_wrapper {
    margin-top: 64px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper_desktop {
        display: none;
    }

    .wrapper_mobile {
        display: block;
    }

    .sider {
        display: none;
    }

    .header_wrapper {
        display: none;
    }

    .main_wrapper {
        margin-top: 32px;
    }

    .buttons_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    .subject_title {
        margin-bottom: 0;
    }

    .contact_form {
        padding: 0;
    }

    .button_block {
        width: 100%;
    }
}

@media only screen
and (max-device-height: 788px) {

    .modal_title {
        margin-bottom: 25px;
    }

    .subject_block {
        text-align: left;
        margin-bottom: 12px;
    }

    .buttons_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .contact_form {
        padding: 0;
    }

    .button_block {
        width: 100%;
    }
}

@media only screen and (max-device-width: 500px) {
    .subject_title {
        margin-bottom: 0;
    }

    .contact_form {
        padding: 0;
    }

    .button_block {
        width: 100%;
    }
}

@media only screen and (max-device-width: 400px) {

    .contact_form {
        padding: 0;
    }

    .modal_title {
        margin-bottom: 40px;
        color: #282828;
        max-width: 199px;
    }
    .buttons_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .subject_title {
        margin-bottom: 0;
    }

    .button_block {
        width: 100%;
    }

}
