.app {
    position: relative;
}

html,
body {
    background: #F9FCFF;
    max-width: 100vw;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4 ,p {
    margin: 0;
    padding: 0;
}

body header {
    padding: 32px 80px;
    display: flex;
    align-items: center;
}

h1 {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.28px;
}

.desktop_header {
    display: block;
}

.mobile_header {
    display: none;
}

.mobile_header_login {
    display: none;
}

@media only screen
and (max-width: 768px) {
    .desktop_header {
        display: none;
    }

    .mobile_header {
        display: block;
        /* position: fixed; */
        /* z-index: 2; */
        width: 100%;
    }

    .mobile_header_login {
        display: block;
        z-index: 2;
        width: 100%;
        background-color: #F6F6F6;
    }

    h1 {
        color: #2A2E37;
        text-align: center;
        font-family: Manrope, sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span {
        color: #77797E;
        text-align: center;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-style: normal;
        /* font-weight: 400; */
        line-height: normal;
    }
}
