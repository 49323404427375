.wrapper {
    background-color: #F6F6F6;
    padding: 88px 0;
}

.title {
    color: #0E492E;
    text-align: center;
    font-family: Manrope;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 56px;
}

.subtitle {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: -0.64px;
    width: 800px;
}

.page_description {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.list_wrapper {
    margin-top: 88px;
    display: flex;
    width: 100%;
    padding: 80px 150px;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    border-radius: 32px;
    background: #FFF;
    box-shadow: -4px 0px 20px 0px rgba(0, 0, 0, 0.08), 4px 4px 20px 0px rgba(0, 0, 0, 0.08);
}

.table_header {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 630px;
}

.table_item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item_number {
    border-radius: 100px;
    background: #313131;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;

    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.item_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 20px;
}

.item_description {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.colorfull {
    color: #4DB887;
}

.contact_us_block {
    border-radius: 32px;
    border: 1.5px solid #C8DBD2;

    width: 100%;
    padding: 80px 150px;
    box-sizing: border-box;
    margin-top: 88px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact_us_block_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 32px;
}

.contact_us_block_subtitle {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.button_wrapper {
    margin-top: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 297px;
}

.stay_safe {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-top: 64px;
}

@media only screen
and (max-device-width: 768px) {

    .wrapper {
        padding: 33px 0 88px;
    }
    .subtitle {
        max-width: 100%;
    }

    .list_wrapper {
        padding: 60px 38px;
        margin-top: 72px;
    }

    .table_header {
        width: 100%;
    }

    .contact_us_block {
        padding: 49px 46px;
    }
}

@media only screen
and (max-device-width: 500px) {
    .title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 112.5% */
        letter-spacing: -0.64px;
        margin-bottom: 16px;
    }

    .subtitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;   
        line-height: 26px; /* 130% */
        letter-spacing: -0.4px;
    }

    .list_wrapper {
        padding: 40px 24px;
        gap: 40px;
    }

    .table_header {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .item_title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        margin-bottom: 16px;
    }

    .item_description {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .contact_us_block {
        padding: 64px 24px;
    }

    .contact_us_block_title {
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 112.5% */
        letter-spacing: -0.64px;
        margin-bottom: 24px;
    }
    .contact_us_block_subtitle {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 130% */
        letter-spacing: -0.4px;
        margin-bottom: 48px;
    }

    .button_wrapper {
        margin: 0;
    }

    .stay_safe {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }


}
