.wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999999;
    padding: 40px;
    background: #F6F6F6;
}

.content {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: 1px solid #4DB887;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: Manrope, sans-serif;
}

.flex {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img {
    width: 48px;
    height: 48px;
}

.title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 16px;
    color: #282828;
    margin-top: 8px;
}

.subtitle {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    color: #313131;
    margin-bottom: 0;
    letter-spacing: -0.52px;
    max-width: 84%;
}

.button_wrapper {
    width: 180px;
    margin-top: 48px;
    display: flex;
    justify-content: center;
}

.description_block {
    position: absolute;
    bottom: 120px;
    transform: translate(-50%, 0);
    left: 50%;
}

.description {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.colorfull {
    color: #4DB887;
}

@media screen and (max-width: 375px) {
    .wrapper {
        padding: 48px 16px 16px 16px;
    }

    .content {
        border-radius: 6px;
    }
    .img {
        width: 32px;
        height: 32px;
    }
    .title {
        font-size: 26px;
        line-height: 30px;
        letter-spacing: 0.52px;
    }
    .subtitle {
        max-width: 87%;
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #B5B5B5;
    }
    .description_block {
        bottom: 64px;
        width: 259px;
    }
    .description {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.24px;
        
    }
    .colorfull {
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.24px;
        font-weight: 600;
    }
}
