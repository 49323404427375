.formTemplatesWrapper {
    padding: 40px 32px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    max-width: 864px;
    margin-bottom: 32px;
    margin-top: 64px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.transfersWrapper {
    padding: 40px 32px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    margin-bottom: 37px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}


.transfersWrapperTitle {
    padding: 0;
    margin-bottom: 24px;
}

.form {
    display: flex;
    flex-direction: column;
}

.accountInfoRow {
    display: flex;
    gap: 24px;
    height: 76px;
    margin-bottom: 24px;
}

.dropdown_wrapper {
    width: 316px;
    height: 52px;
}

.inputsWrapper {
    display: flex;
    gap: 24px;
}

.inputWrapper {
    width: 316px;
}

.buttonWrapper {
    height: 100%;
    align-self: flex-end;
    width: 120px;
}

.labelText {
    color: #979797;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
    font-family: 'Manrope', sans-serif;
}

.tableButtonWrapper {
    margin-top: 48px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.transfersTable thead > tr > th {
    font-family: 'Manrope', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;
}

.transfersTable thead > tr > th::before {
    content: none !important;
}

.transfersTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
}

.linkDiv {
    color: #4DB887 !important;
    text-transform: initial !important;
    text-decoration: underline;
    width: fit-content;
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 0;

    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.linkDiv:hover {
    color: #89C0E9 !important;
    border-color: #89C0E9 !important;
}


tbody .typeColumn {
    text-transform: initial !important;
}

.actionBlock {
    display: flex;
    gap: 4px;
    color: #FF6471;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-transform: initial;
    cursor: pointer;
    transition: all .3s ease-in-out;
    align-items: center;
}

.actionBlock p {
    margin-bottom: 0;
}

.actionBlock:hover {
    color: #FF8A94;
}

.deleteIcon {
    height: 16px;
    width: 16px;
    transition: all .3s ease-in-out;
}

.actionBlock:hover svg path {
    fill: #FF8A94;
    transition: all .3s ease-in-out;
}

.templateMobile {
    display: none;
}

.capitalize {
    text-transform: capitalize;
}

@media only screen
and (max-width: 768px) {
    .transfersWrapperTitle {
        font-size: 26px;
        font-weight: 600;
    }

    .formTemplatesWrapper {
        padding: 32px 16px;
        margin-top: 0;
    }

    .accountInfoRow {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: auto;
        margin-bottom: 24px;
    }

    .dropdown_wrapper {
        width: 100%;
        height: auto;
    }

    .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .inputWrapper {
        width: 100%;
    }

    .buttonWrapper {
        width: 100%;
    }

    .transfersTable {
        display: none;
    }

    .templateMobile {
        border-radius: 12px;
        background: #FFF;
        padding: 32px 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .transfersWrapper {
        padding: 0;
        background-color: transparent;
        box-shadow: none;
    }

    .templateLabel {
        margin-bottom: 9px;
        color: #979797;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    

    .flex {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }


    .linkDiv {
        display: block;
        text-align: left;
        width: 100%;
        padding: 0;
        word-break: break-word;
        margin-bottom: 6px;
    }

    
    .templateItemWrapper {
        text-align: left;
    }

    .templateLabel {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        letter-spacing: -0.24px;
        margin-bottom: 6px;
    }

    .flex {
        display: block;
    }

    .templateValueWrapper {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-align: left !important;
    }

    .templateValue {
        color: #2A2E37;
        font-family: Manrope, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        word-break: break-word;
        text-align: start !important;
    }

}
