.button {
    padding: 8px 24px;
    border-radius: 120px;
    border: 1.5px solid #C8DBD2;
    display: flex;
    align-items: center;
    height: 42px;
    transition: .3s all ease;
}

.personal_button {
    border-radius: 4px;
    border: 1.5px solid #4DB887;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease;
    z-index: 2;
}

.personal_button span {
    color: #4DB887;
    text-align: center;
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    transition: .3s color;
}

.personal_button:hover {
    border: 1.5px solid #7DD9AF!important;
    color: #7DD9AF!important;
    transition: .3s all ease;
}

.personal_button:hover > span {
    color: #7DD9AF!important;
    transition: .3s color;
}

.personal_button:hover svg path {
    fill: #7DD9AF;
    transition: 300ms !important;
}

.button span {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
    transition: .3s all ease;
}

.button:hover {
    background-color: #E7E7E7!important;
    border: 1.5px solid #E7E7E7!important;
    transition: .3s all ease;
}

svg path {
    transition: 300ms !important;
}

.button:hover svg path {
    fill: #E7E7E7;
    transition: 300ms !important;
}

.disabled {
    background-color: transparent !important;
    transition: border .3s ease;
    cursor: progress;
    border: 1.5px solid #B5B5B5 !important;
}

.disabled span {
    color: #B5B5B5 !important;
}

.disabled:hover {
    background-color: transparent !important;
    transition: border .3s ease;
    cursor: progress;
    border: 1.5px solid #B5B5B5 !important;
}

.confirm {
    border-color: #FFBB54 !important;
}

.confirm span {
    color: #FFBB54 !important;
}

.confirm:hover {
    border-color: #FEC979 !important;
    color: #FEC979 !important;
}

.confirm:hover span{
    color: #FEC979 !important;
}

.confirm svg path {
    fill: #FFBB54;
}

.confirm:hover svg path {
    fill: #FEC979;
}

.error {
    border-color: #F01818 !important;
    color: #F01818 !important;
}

.error:hover {
    border-color: #FF8A94 !important;
    color: #FF8A94 !important;
}

.error:hover span{
    border-color: #FF8A94 !important;
    color: #FF8A94 !important;
}

.error svg path {
    fill: #F01818;
}


.error:hover svg path {
    fill: #FF8A94;
}

.error span {
    font-size: 16px;
    font-family: Manrope, sans-serif;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    color: #F01818!important;
    transition: .3s all ease;
}

.info {
    border-color: #FFA724 !important;
    color: #FFA724 !important;
}

.info:hover {
    border-color: #FFC774 !important;
    color: #FFC774 !important;
}

.info:hover span{
    border-color: #FFC774 !important;
    color: #FFC774 !important;
}

.info svg path {
    fill: #FFC774;
}

.info:hover svg path {
    fill: #FFC774;
}

.info span {
    font-size: 14px;
    font-family: Manrope, sans-serif;
    font-weight: 600;
    line-height: 18px;
    text-transform: capitalize;
    color: #FFA724!important;
    transition: .3s all ease;
}

.medium {
    height: 44px;
}

.large {
    height: 52px;
}

.small {
    height: fit-content;
    padding: 12px 24px;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .my_btn {
        width: 100%;
    }

    .button {
        width: 100%;
        justify-content: center;
    }

    .button span {
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: -0.24px;
    }
}
