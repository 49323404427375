.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 80px;
}

.my_collapse {
    width: 100%;
}

.top_block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 56px 48px;
    border-radius: 32px;
    background: #DEEFFC;
    box-sizing: border-box;
    margin-bottom: 64px;
}

.collapse_text {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-top: 18px;
}

.content {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
}

.panelStyle {
    border-radius: 12px!important;
    border: 1.5px solid #EEEFEF;
    background: #FFF;
    margin-bottom: 24px;
    padding: 24px 32px;
    box-sizing: border-box;
}

.panelStyle div {
    padding: 0!important;
    align-items: start!important;
}

.panelStyle div span {
    color: #2A2E37!important;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.flex {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    gap: 30px;
    width: 100%;
    margin-bottom: 32px;
}

.text {
    width: 67%;
    box-sizing: border-box;
    font-family: Manrope, sans-serif;
    text-align: justify;
}

.screenshot {
    width: 33%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
}

.screenshot img{
    width: 100%;
    box-sizing: border-box;
}

.link_small {
    color: #4DB887;
    font-size: 20px;
    font-family: Manrope,sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
    text-decoration-line: underline;
}

.desktopMarginTitle {
    margin-bottom: 32px;
}
.documentSteps {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
}

.bold {
    color: #282828;
    text-align: justify;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.fix_height {
    height: 300px;
    width: 200px;
}

.screenshot .fix_height {
    width: 200px;
}

.listOfDocToBank {
    padding: 0;
    margin-bottom: 32px;
}

.listOfDocToBank li {
    list-style: none;
    margin-bottom: 0;  
}

.circle {
    display: flex;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #000; /* Цвет кружка */
    margin-right: 10px; /* Отступ между кружком и текстом */
}


.dote {
    list-style: none;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
    position: relative;
    padding-left: 13px;
}

.dote::before {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    transform: translateY(-50%);
    width: 5px;  /* Размер кружка */
    height: 5px;
    background-color: black; /* Цвет кружка */
    border-radius: 50%;
}

.dash {
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px;
}
.listOfDocToBank .dote {
    /* list-style: inside; */
}

.listOfDocToBank .dash::before {
    content: '-';
    padding-right: 5px
}



.li {
    margin: 0!important;
}

.ul {
    margin-bottom: 32px;
}

.additionalComponent {
    margin-bottom: 0;
}

.important {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 130% */
    letter-spacing: -0.4px
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 16px 50px;
    }
    .top_block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 56px 16px;
        border-radius: 32px;
        background: #DEEFFC;
        box-sizing: border-box;
        margin-bottom: 64px;
    }
    .flex {
        flex-direction: column;
    }
    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
    }
    /* img {
        width: 100%;
    } */
    .panelStyle div span, .link_small, .collapse_text, .bold {
        font-size: 16px;
        text-align: left;
    }

    .documentSteps {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .panelStyle {
        border-radius: 12px!important;
        border: 1.5px solid #EEEFEF;
        background: #FFF;
        margin-bottom: 24px;
        padding: 16px;
    }
    .text {
        width: 100%;
        font-size: 16px;
    }
    .panelStyle div {
        align-items: center!important;
    }

    .remember_title {
        font-size: 36px;
    }

    .remember_text {
        color: #CECECE;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.32px;
    }
    .screenshot {
        width: 100%;
    }
    .fix_height {
        height: auto;
        width: 100%;
    }
    .screenshot .fix_height {
        width: 100%;
    }

    .ul {
        margin-bottom: 24px;
    } 

    .dote {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .dote::before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        transform: translateY(-50%);
        width: 5px;  /* Размер кружка */
        height: 5px;
        background-color: black; /* Цвет кружка */
        border-radius: 50%;
    }
    
    .dash {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .additionalComponent {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .important {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
    }

    .desktopMarginTitle {
        margin-bottom: 0;
    }

}

