.text_block {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    margin-left: 16px;
    transition: .3s all ease;
}

.active .text_block{
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFF;
    opacity: 0.9;
    border-bottom: 1px solid var(--v-30-neutral-white, #FFF);
}

.inactive:hover .img_block div{
    background: #7DD9AF;
    transition: .3s all ease;
}

.inactive:hover .text_block {
    color: #7DD9AF;
    transition: .3s all ease;
}

.img_block {
    width: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_accounts {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/accounts.svg");
}

.active .img_accounts {
    background-color: #FFF;
}

.img_transfers {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/transfers.svg");
}

.active .img_transfers {
    background-color: #FFF;
}

.img_templates {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/templates.svg");
}

.active .img_templates {
    background-color: #FFF;
}

.img_cards {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-repeat: no-repeat;
    mask-image: url("../../assets/img/personalAccount/cards.svg");
}

.active .img_cards {
    background-color: #FFF;
}

.img_security {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/security.svg");
}

.active .img_security {
    background-color: #FFF;
}

.img_settings {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/settings.svg");
}

.active .img_settings {
    background-color: #FFF;
}

.img_currency {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #fff;
    display: block;
    mask-image: url("../../assets/img/personalAccount/currency.svg");
}

.active .img_currency {
    background-color: #FFF;
}

.img_logout {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #FFF;
    display: block;
    mask-image: url("../../assets/img/personalAccount/logout.svg");
}

@media only screen
and (max-device-width: 768px) {
    .text_block {
        color: #FFF;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }

    .active .text_block {
        width: auto;
        font-family: Manrope, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
    }
}
