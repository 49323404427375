.container {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.wrapper {
    margin: 70px 0 122px;
}

.tabstitle_block {
    width: fit-content;
    border-radius: 8px;
    padding: 6px;
    border: 1.5px solid #EEEFEF;
    display: flex;
    list-style-type: none;
    margin-bottom: 32px;
}

.tabtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #B5B5B5;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
    margin-bottom: 0;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #666;
}

.active_tab {
    background: #0E492E;
    border-radius: 4px;
    color: #FFFFFF;
    outline: none;
}

.active_tab:hover {
    background: #0E492E;
    color: #FFFFFF;
}

.modal_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.modal_content img {
    margin-bottom: 8px;
}

.success {
    color: #4DB887;
    font-weight: 800;
}

.error {
    color: #F01818;
    font-weight: 800;
}

.popup_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.popup_title1 {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.popup_subtitle_block {
    width: 317px;
}

.popup_subtitle {
    font-family: Manrope, sans-serif;
    color: #B5B5B5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 48px;
    text-align: center;
}

.popup_subtitle1 {
    font-family: Manrope, sans-serif;
    color: #B5B5B5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 9px;
    text-align: center;
}

.popup_icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
}

.popup_button_block {
    width: 180px;
    margin: 0 auto;
}

.notify_modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.notify_popup_subtitle {
    font-family: Manrope, sans-serif;
    color: #B5B5B5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
}

.notify_error {
    color: #F01818;
    font-weight: 800;
}

.popup_text_block {
    width: 316px;
}

.input_wrapper {
    margin-bottom: 8px;
    position: relative;
}

.input_wrapper {
    width: 316px;
    margin-bottom: 24px;
}

.input_label {
    color: #B5B5B5;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.buttonWrapper {
    display: flex;
    gap: 8px;
}

.error_text {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
    left: 0;
}

.error_modal_block {
    z-index: 999999;
}

.verify_modal {
    z-index: -1;
}

.block_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen
and (max-device-width: 1440px) {
    .container {
        width: 100%;
    }
}

@media only screen
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .tabstitle_block {
        width: 100%;
        border-radius: 8px;
        padding: 6px;
        border: 1.5px solid #EEEFEF;
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        /* margin-bottom: 48px; */
    }

    .tabtitle {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #979797;
        text-decoration: none;
        padding: 8px 24px;
        cursor: pointer;
        transition: .3s all ease;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .active_tab {
        background: #0E492E;
        border-radius: 4px;
        color: #FFF;
        outline: none;
    }

    .input_wrapper {
        width: 100%;
        margin-bottom: 20px;
    }

    .block_center {
        margin-bottom: 8px;
    }

    .popup_subtitle {
        width: 232px;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0 auto;
        margin-bottom: 32px;
    }

    .popup_button_block {
        width: 100%;
        margin: 0;
    }

    .buttonWrapper {
        flex-direction: column;
        width: 100%;
    }
}
