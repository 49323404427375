.wrapper {
    max-width: 720px;
}

.common_desc {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #282828;
    margin-bottom: 12px;
}

.autentication_list_block {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    box-sizing: border-box;
    padding: 40px 32px;
    margin-top: 32px;
    margin-bottom: 116px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.autentication_item {
    margin-bottom: 32px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.autentication_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #282828;
    width: 90%;
}

.autentication_item_description {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #282828;
}

.title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #282828;
    margin-bottom: 32px;
}

.settings_desc {
    margin-bottom: 32px;
}

.desc_item {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #282828;
    margin-bottom: 12px;
}

.qr_image {
    border: 1.5px solid #EEEFEF;
    border-radius: 12px;
    width: 160px;
    height: 160px;
}

.qr_block {
    display: flex;
    align-items: center;
    height: fit-content;
    margin-bottom: 48px;
    gap: 24px;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
}

.input_wrapper {
    margin-top: 8px;
    width: 288px;
    position: relative;
}

.error {
    position: absolute;
    bottom: -20px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
}

.my_root_switch {
    background: transparent;
    border: 1px solid #E7E7E7;
    height: 28px;
    width: 50px;
    display: flex;
}

.my_switch div {
    width: 22px!important;
    height: 22px!important;
}

.my_switch div::before {
    border-radius: 50% !important;
    box-shadow: none !important;
}

.input_block {
    margin-bottom: 48px;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
}

.buttons_block_end {
    display: flex;
    justify-content: flex-end;
}

.button_wrapper {
    width: 180px;
    padding-left: 32px;
}

.login_history_link {
    color: #4DB887;
    border-bottom: 1px solid #4DB887;
    cursor: pointer;
}

.tableWrapper {
    padding: 40px 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.table_title {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    padding-left: 32px;
}

.statementTable thead > tr > th {
    color: #B5B5B5 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 0 0 6px !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;

    font-family: Manrope, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 6px 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
    word-break: break-all;
}

.statementTable tbody > tr > td > p {
    margin: 0!important;
}

.statementTable tbody > tr > td:first-child,
.statementTable tbody > tr > td:last-child,
.statementTable thead > tr > th:last-child,
.statementTable thead > tr > th:first-child{
    padding-left: 32px !important;
    padding-right: 6px !important ;
}

.statementTable tbody > tr > td:first-child
{
    padding-left: 32px!important;
}

.table_row_dark {
    background-color: #EEEFEF !important;
}

.notify {
    color: #4DB887;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    margin-top: 32px;
}

.buttonWrapper {
    display: flex;
    gap: 8px;
}

.popup_text_block {
    width: 318px;
}

.arrow_top {
    position: absolute;
    top: 0;
}

.arrow_down {
    position: absolute;
    bottom: 0;
}

.sort_icon_active svg path{
    stroke: #282828
}

.arrows_wrapper {
    width: 10px;
    display: flex;
    flex-direction: column;
    height: 18px;
    position: relative;
}

@media only screen
and (max-device-width: 768px) {
    .autentication_list_block {
        width: 100%;
        padding: 32px 16px;
    }

    .my_root_switch {
        background: transparent;
        border: 1.5px solid #EEEFEF;
        height: 26px;
        display: flex;
        width: 45px;
    }

    .autentication_title {
        text-align: left;
    }

    .buttonWrapper {
        flex-direction: column;
        width: 100%;
    }

    .my_switch div {
        width: 20px!important;
        height: 20px!important;
    }

    .common_desc {
        font-size: 14px;
    }

    .autentication_item_description {
        font-size: 14px;
    }
}
