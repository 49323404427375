.input_block {
    width: 316px;
    margin-bottom: 60px;
    position: relative;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.from_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    width: 384px;
}

.errorButtonsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 384px;
}

.modal_title {
    color: #282828;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 24px;
}

.modal_subtitle {
    color: #979797;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 316px;
    margin-bottom: 20px;
}

.from_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error_field {
    color: #F01818;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    border-bottom: 20px;
}

.iconBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
}

@media only screen
and (max-device-width: 768px) {
    .input_block {
        margin-bottom: 48px;
        width: 100%;
    }

    .buttonsWrapper {
        flex-direction: column;
        width: 100%;
    }

    .modal_subtitle {
        width: 100%;
    }
}
