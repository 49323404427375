.waveform {
    --uib-size: 32px;
    --uib-speed: 1s;
    --uib-color: black;
    --uib-line-weight: 4px;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: var(--uib-size);
    height: calc(var(--uib-size) * 0.9);

}

.waveform__bar {
    width: var(--uib-line-weight);
    height: 100%;
    background-color: var(--uib-color);
    border-radius: 2px;
}

.waveform__bar:nth-child(1) {
    animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.6) infinite;
    border-radius: 2px;
}

.waveform__bar:nth-child(2) {
    animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.5) infinite;
    border-radius: 2px;
}

.waveform__bar:nth-child(3) {
    animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.4) infinite;
    border-radius: 2px;
}

.waveform__bar:nth-child(4) {
    animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.5) infinite;
    border-radius: 2px;
}

.waveform__bar:nth-child(5) {
    animation: grow var(--uib-speed) ease-in-out
    calc(var(--uib-speed) * -0.6) infinite;
    border-radius: 2px;
}


@keyframes grow {
    0%,
    100% {
        transform: scaleY(0.3);
    }

    50% {
        transform: scaleY(1);
    }
}


.one{
    background-color: #2046A1;
    border-radius: 2px;
}


.two{
    background-color: #3A65B3;
    border-radius: 2px;
}

.three  {
    background-color: #5583C5;
    border-radius: 2px;
}

.four  {
    background-color: #6FA2D7;
    border-radius: 2px;
}

.five  {
    background-color: #89C0E9;
    border-radius: 2px;
}

.text {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
}
