.wrapper {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container {
    width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    margin: 0 auto;
}

.main_text_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 120px 0;
}

.main_title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 56px;
}

.main_text {
    color: #282828;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 879px;
}

.my_link {
    color: #4DB887;
}

.provides_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 72px;
    margin-bottom: 120px;
}

.provides_cards_block {
    display: flex;
    gap: 24px;
    width: 100%;
}

.left_card {
    border-radius: 32px;
    background: #C8DBD2;
    width: 100%;
    padding: 100px 65px;
    box-sizing: border-box;
    color: #282828;
    display: flex;
    flex-direction: column;
}

.right_card {
    border-radius: 32px;
    background: #E7F0EC;
    width: 100%;
    padding: 100px 65px;
    box-sizing: border-box;
    color: #282828;
    display: flex;
    flex-direction: column;
}

.card_title {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 40px;
}

.card_text {
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
}

.card_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 100px 0;
    border-radius: 32px;
    border: 1.5px solid #C8DBD2;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 33px 16px 88px;
    }
    .main_text_block {
        margin: 0;
        margin-bottom: 96px;
    }
    .main_title, .provides_title {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .main_text {
        width: auto;
        font-size: 20px;
        padding: 0 22px;
    }
    .my_link {
        font-size: 20px;
    }
    .provides_block {
        gap: 22px;
        margin-bottom: 40px;
    }
    .provides_cards_block {
        flex-direction: column;
        gap: 20px;
    }
    .left_card, .right_card {
        padding: 35px;
    }
    .card_title {
        width: 250px;
        margin: 0px auto 20px;
        font-size: 26px;
        line-height: 30px;
        color: #282828;
    }
    .card_text, .card_footer {
        color: #282828;
        font-weight: 400;
        font-size: 20px;
    }
    .card_footer {
        padding: 50px 0;
    }
}