.info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    flex-direction: column;
    width: 100%;
}

.title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
}

.content > :nth-child(3) {
    margin-bottom: 87px;
}

.content_business > :nth-child(3) {
    margin-bottom: 133px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input_block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    quotes: 100%;
}

.input_label {
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.input_label ~ div {
    width: 100%;
}

.error {
    color: #F01818;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 18px;
    align-items: center;
    gap: 8px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #F01818;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
}

.error_wrapper {
    width: 315px;
}
