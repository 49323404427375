.account_select {
    display: flex;
    width: fit-content;
    max-width: 1148px;
    padding: 72px 144px 32px 144px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 40px;
    border-radius: 24px;
    background: #FFF;
}

.account_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 96px;
}

.account_selection {
    display: flex;
    flex-direction: column;
    gap: 48px;
    align-items: center;
    justify-content: center;
}

.account_back {
    text-align: center;
}

.account_back_text {
    margin-right: 8px;
    color: #B5B5B5;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.account_back_link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.account_selection_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    letter-spacing: -0.52px;
}

.account_selection_content {
    display: flex;
    align-items: center;
    gap: 48px;
}

.account_selection_item {
    display: flex;
    padding: 48px 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 24px;
    border-radius: 32px;
    background: #FFF;
    box-shadow: -10px 10px 40px 0px rgba(0, 0, 0, 0.06), 10px 10px 40px 0px rgba(0, 0, 0, 0.06);
}

.item_personal_image {
    width: 80px;
    height: 80px;
    background-image: url('../../../assets/img/personal.svg');
    background-repeat: no-repeat;
    transition: background-image .3s ease-in;
}

.item_business_image {
    position: relative;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
}

.item_business_image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/img/business.svg');
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;
    opacity: 1;
}

.item_business_image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../../assets/img/business_icon.svg');
    background-repeat: no-repeat;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}

.account_selection_item:hover .item_business_image::before {
    opacity: 0;
}

.account_selection_item:hover .item_business_image::after {
    opacity: 1;
}

.item_title {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; 
    letter-spacing: -0.52px;
}

.item_description {
    color: #282828;
    text-align: center;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    max-width: 342px;
}

.item_button {
    margin-top: 32px;
}

.item_button button {
    background: #B5B5B5;
    transition: background-color .3s ease-in;
    border: none;
}

.account_selection_item:hover .item_personal_image {
    width: 80px;
    height: 80px;
    background-image: url('../../../assets/img/personal_icon.svg');
    background-repeat: no-repeat;
    transition: background-image .3s ease-in;
}


.account_selection_item:hover button {
    background: #4DB887;
    transition: background-color .3s ease-in;
}

@media only screen
and (max-device-width: 768px) {
    .account_select {
        width: 100%;
        margin: 116px 16px;
        max-width: auto;
        display: flex;
        padding: 40px 16px;
        border-radius: 8px;
        gap: 24px;
    }

    .account_content {
        gap: 40px;
    }
    
    .account_selection {
        gap: 40px;
    }

    .account_selection_title {
        color: #282828;
        text-align: center;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; 
        letter-spacing: -0.52px;
        max-width: 200px;
    }
    
    .account_selection_content {
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .account_selection_item {
        max-width: 311px;
        padding: 48px 32px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        border-radius: 32px;
        box-shadow: -10px 10px 40px 0px rgba(0, 0, 0, 0.06), 10px 10px 40px 0px rgba(0, 0, 0, 0.06);
    }
}