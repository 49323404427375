.title {
    margin-bottom: 32px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
}

.row {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.row_name {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2A2E37;
    width: 180px;
}

.row_value {
    max-width: max-content;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.buttons_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button_wrapper {
    width: 180px;
}


.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-size: 24px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-size: 16px;
    font-family: Manrope, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_block {
    width: 180px;
    margin: 0 auto;
}

.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.success {
    color: #4DB887;
    font-weight: 800;
}

.error {
    color: #F01818;
    font-weight: 800;
}

.popup_subtitle {
    font-family: Manrope, sans-serif;
    color: #B5B5B5;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 24px;
    text-align: center;
}

.popup_link {
    color: #4DB887;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.data_block {
    margin-bottom: 24px;
}

@media only screen
and (max-device-width: 768px) {
    .row_name {
        font-family: 'Manrope', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #2A2E37;
        width: 50%;
    }

    .row span {
        max-width: initial;
        text-align: left;
        display: block;
        width: 50%;
        word-break: break-word;
    }

    .buttons_block {
        flex-direction: column;
        gap: 16px;
    }

    .button_wrapper {
        width: 100%;
    }

}

@media only screen
and (max-device-width: 501px) {
    .row {
        display: block;
    }

    .row_name {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 4px;
    }

    .row_value {
        font-size: 14px;
        font-style: normal;
        font-weight: 800;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
    }

    .popup_subtitle {
        width: 224px;
        margin: 0 auto;
        margin-bottom: 24px;
    }
}