.modalContent {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 8px;
    margin-top: 60px;
    justify-content: center;
}

.buttonBlock {
    width: 180px;
}

.icon {
    width: 32px;
    height: 32px;
}

@media only screen
and (max-device-width: 768px) {
    .buttonBlock {
        width: 100%;
    }

    .buttonsWrapper {
        display: flex;
        gap: 8px;
        margin-top: 60px;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
}

