.statementWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    cursor: default;
    margin: 51px 0 31px;
    max-width: 720px;
}


.downloadTitle {
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 8px;
}

.buttonTitle {
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    cursor: pointer;
    margin: 0;
    color: #4DB887;
    transition: .3s all ease;
}

.buttonTitle:hover {
    color: #7DD9AF;
    transition: .3s all ease;
}

.separator {
    padding-bottom: 4px;
    width: 2px;
    height: 14px;
    background-color: #282828;
}

.printWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .statementWrapper {
        font-size: 16px;
        position: initial;
        margin-top: 24px;
        flex-direction: column;
        gap: 40px;
        align-items: flex-start;
    }

    .buttonsWrapper {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
}
