.wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.row {
    display: flex;
    align-items: center;
    gap: 8px;
}

.row_name {
    width: 50%;
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.row_value {
    width: 60%;
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
