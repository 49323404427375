.input_name {
    margin-bottom: 7px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 17px 16px;
    min-width: 100%;
    width: 100%;
    height: 100%;
    height: 52px;
    background: #FFFFFF;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #282828;
    border: 1.5px solid #E7E7E7;
    text-transform: capitalize;
    transition: border .3s
}

.my_input::placeholder {
    color: #E7E7E7;
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.my_input:focus {
    outline: 1.5px solid #979797;
}

.error {
    border: 1.5px solid #F01818;
}

.error_message {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #F01818;
    bottom: -20px;
    position: absolute;
}

.dropdownWrapper {
    position: relative;
    width: 100%;
    /* пока не удалять. Проверить на деве и мобилке */
    
    /* height: 100%; */

    /* пока не удалять. Проверить на деве и мобилке */
}

.dropdownWrapper:hover > .my_input {
    border: 1px solid #B5B5B5;
}

.mySelect {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    justify-content: flex-end;
}

.selectedBlock {
    display: flex;
    align-items: center;
    padding-left: 25px;
    margin-right: 10px
}

.mySelect img {
    margin-right: 5px;
}

.optionsWrapper {
    width: 100%;
    max-height: 224px;
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 59px;
    padding: 18px;
    background: #FFFFFF;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    transition: all 0.3ms ease;
    z-index: 999999;
    box-sizing: border-box;
}

.optionsWrapperCurr {
    width: 100%;
    max-height: 224px;
    position: absolute;
    overflow-y: scroll;
    left: 0;
    top: 59px;
    padding: 8px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    transition: all 0.3ms ease;
    z-index: 999999;
    box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
    margin-top: 25px;
    padding-top: 25px;
    height: 30px;
    background: #EBEBEB;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
    margin-left: -30px;
}

.none {
    display: none;
}

.arrow {
    transform: rotate(0);
    transition: .3s all ease;
    margin: 0 15px 0 8px;
    width: 12px;
    height: 12px;
}

.arrowReverse {
    transform: rotate(180deg);
    transition: .3s all ease;
    margin: 0 15px 0 8px;
    width: 12px;
    height: 12px;
}

.option {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
    width: auto;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    padding: 8px 16px;
    text-transform: capitalize;
}

.option:hover {
    border-radius: 4px;
    background: #E7E7E7;
}

.countryName {
    display: flex;
    align-items: center;
    word-break: break-all;
}

.flag {
    width: 24px;
}

.disableInput {
    background: #EEEFEF;
}

@media only screen and (max-width: 640px) and (min-width: 370px) {

    .optionsWrapper {
        width: 100%;
        max-height: 224px;
        position: absolute;
        overflow-y: scroll;
        left: 0;
        top: 59px;
        padding: 16px 8px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        transition: all 0.3ms ease;
        z-index: 999999;
        box-sizing: border-box;
    }

    .option {
        padding: 8px;
    }


    .mySelect img {
        margin-right: 0;
    }
}
