.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFF;
}

.block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 48px;
    height: 100%;
}

.links_block {
    display: flex;
    gap: 16px;
}

.header_link {
    color: #0E492E;
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 70px;
    border: 1px solid #ffffff00;
    transition: .5s all ease;
}

.header_link.header_link_active {
    color: #4DB887;
    border: 1px solid #E7F0EC;
    transition: .5s all ease;
}

.header_link:hover {
    color: #4DB887!important;
    transition: .5s all ease;
}

.logo_block {
    margin-right: 120px;
}

.buttons_block {
    display: flex;
    align-items: center;
    gap: 10px;
}

.button_wrapper_short {
    width: 110px;
}

.button_wrapper {
    width: 180px;
}

.logo {
    mask-image: url("../../assets/img/logo.svg");
    background-color: #0E492E;
    cursor: pointer;
    width: 155px;
    height: 58px;
    transition: .5s all ease;
}

.logo:hover {
    background-color: #4DB887;
    transition: .5s all ease;
}

.round {
    width: max-content;
    border-radius: 60px;
    cursor: pointer;
    display: flex;
    padding: 8px 18px;
    box-sizing: border-box;
    transition: .3s all ease;
    height: fit-content;
    background: #FFFFFF;
    border: 1.5px solid #E7E7E7;
    justify-content: center;
    align-items: center;
    gap: 15px;
    transition: .3s all ease;
}

.user_typography {
    color: #B5B5B5;
    transition: .3s all ease;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: -0.24px;
}

.round:hover .user_typography {
    transition: .3s all ease;
    color: #282828;
}

.round .name, .id {
    margin-bottom: 0;
}

.user {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #E7E7E7;
    display: block;
    mask-image: url("../../assets/img/personalAccount/user.svg");
    top: 18px;
    right: 18px;
    transition: .3s all ease;
}

.round:hover .user {
    transition: .3s all ease;
    background-color: #282828;
}

@media only screen
and (min-device-width: 375px)
and (max-device-width: 768px) {
    .links_block {
        flex-direction: column;
        margin-top: 50px;
    }
    .header_link {
        font-family: Manrope, sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        color: #0E492E;
    }
    .buttons_block {
        display: flex;
        
    }
    
}
