.wrapper {
    background: #F4F4F4;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
}

.container {
    width: 1440px;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    box-sizing: border-box;
    flex-direction: column;
}

.title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 80px;
}

.table_wrapper {
    width: 100%;
    display: block;
}

.table_header {
    padding: 16px 40px;
    border-radius: 150px;
    background: #282828;
    display: flex;
    justify-content: space-between;
    width: 100%;

    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 32px;
    box-sizing: border-box;
}

.table_header span {
    width: 100%;
    text-align: center;
}

.table_body {
    position: relative;
    border-radius: 32px;
    background: #FFF;
    padding: 64px 40px;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 4px 4px 24px 0 rgba(0, 0, 0, 0.06), -4px 0 24px 0 rgba(0, 0, 0, 0.06);
}

.table_body_subtitle {
    border-radius: 140px;
    background: #F4F4F4;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 32px;
    text-align: center;
    padding: 8px 0;

    color: #282828;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 78px;
    margin-bottom: 16px;
}

.row span:first-child {
    text-align: left;
}

.row span {
    width: 100%;
    text-align: center;
}

.values_block {
    margin-bottom: 48px;
}

.attention {
    position: absolute;
    bottom: -34px;
    left: 0;

    color: #282828;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.table_wrapper_mobile {
    display: none;
}

.description_block {
    width: 60%;
    margin-top: 32px;
    margin-bottom: 88px;
}

.description_block p {
    color: #282828;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {
    .table_wrapper {
        display: none;
    }
    .container {
        width: 100%;
        padding: 33px 20px 100px;
        gap: 0;
    }
    .title {
        font-size: 36px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 0;
    }
    .table_wrapper_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 24px;
        margin-top: 20px;
    }
    .container_business_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 280px;
        background: #E7F0EC;
        border-radius: 32px;
    }
    .container_personal_mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 280px;
        background: #C8DBD2;
        border-radius: 32px;
    }
    .title_mobile {
        font-size: 26px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0;
        margin-bottom: 32px;
    }
    .table_wrap {
        width: 100%;
    }
    .table_header span {
        font-size: 20px;
        font-weight: 600;
        color: #FFF;
        text-align: start;
    }
    .table_body {
        padding: 30px 20px;
        margin-bottom: 24px;
    }
    .table_body_subtitle {
        font-size: 16px;
        margin-bottom: 18px;
    }
    .row {
        flex-direction: column;
        gap: 0;
    }
    .row span:first-child {
        color: #282828;
        font-weight: 400;
    }
    .row span {
        text-align: left;
        font-weight: 600;
        line-height: 20px;
        color: #4DB887;
    }
    .values_block {
        margin-bottom: 18px;
    }
    .description_block {
        width: 90%;
        margin-top: 24px;
        margin-bottom: 48px;
    }
    .description_block:last-child {
        margin-bottom: 0;
    }
    .description_block p {
        color: #282828;
        font-family: Manrope;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.32px;
    }

}
