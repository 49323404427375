.accountTopUpWrapper {
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.detailsWrapper{
    background-color: #FFFFFF;
    padding: 24px 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 180px;
}

.info_wrapper .data_wrapper {
    border-bottom: .7px solid #E7E7E7;
    padding-top: 24px;
    padding-bottom: 24px;
}

.info_wrapper .data_wrapper:last-child {
    border-bottom: none;
    padding-bottom: 0px;
}

.detailsTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin: 0;
}

.labelText {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    gap: 8px;
    margin-bottom: 9px;
}

.labelText img {
    cursor: pointer;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.line {
    display: flex;
    align-items: center;
    gap:24px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}
.field {
    margin: 0;
    width: 225px;
}

.info {
    margin: 0;
    font-weight: 600;
    width: 400px;
}

.collapse_text {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    margin-top: 18px;
}

.content {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
}

.panelStyle {
    border-radius: 12px!important;
    border: 1.5px solid #EEEFEF!important;
    background: #FFF;
    padding: 14px 18px;
}

.panelStyle div {
    padding: 0!important;
}

.panelStyle div span {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.topUpSubtitle {
    color: #2A2E37;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
}

.tabstitle_block {
    border-radius: 8px;
    padding: 6px;
    border: 1.5px solid #EEEFEF;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
    width: 100%;
}

.tabtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.tabtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
    width: 100%;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.active_tab {
    background: #E3F7FE;
    border-radius: 4px;
    color: #2046A1;
    outline: none;
}

.tabstitle_block {
    width: fit-content;
    padding: 6px;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
}

.separator {
    width: 1.5px;
    height: 20px;
    background-color: #E7E7E7;
}

.tabtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #B5B5B5;
    text-decoration: none;
    cursor: pointer;
    transition: .3s all ease;
    margin: 0 20px!important;
    text-align: center;
}

.active_tab {
    color: #0E492E;
    outline: none;
    text-decoration: underline #0E492E;
}

.spanHelper {
    display: flex;
}

.spanHelper img {
    margin-left: 10px;
}

.form_row {
    display: flex;
    gap: 24px;
    width: 100%;
}

.input_block {
    width: 100%;
}

.data_wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.row {
    display: flex;
    gap: 24px;
}

.row span {
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #282828;
    text-transform: capitalize;
    min-width: 200px;
}

.dropdown_wrapper {
    height: 52px;
}

@media only screen
and (max-device-width: 768px) {
    .detailsWrapper{
        padding: 32px 16px;
    }

    .form_row {
        display: flex;
        flex-direction: column;
    }

    .row {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }

    .row span {
        min-width: initial;
        text-align: left;
    }

    .detailsTitle {
        font-size: 26px;
        width: 400px ;
    }

}

