body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-checkbox .ant-checkbox-inner{
  width: 22px;
  height: 22px;
  border: 1px solid #E7E7E7;
  border-radius: 2px;
  background-color: #FFF;
}

.ant-checkbox:hover .ant-checkbox-inner:hover{
  border: 1px solid #7DD9AF;
}

.ant-checkbox-inner {
  background-color: #7DD9AF;
  border-color: #7DD9AF;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7DD9AF;
  border-color: #7DD9AF;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: #7DD9AF;
  border-color: transparent;
}

.ant-checkbox-checked:hover .ant-checkbox-inner:hover {
  background-color: #7DD9AF;
  border-color: #7DD9AF;
}

.ant-checkbox .ant-checkbox-inner:after {
  inset-inline-start: 30%;
}
