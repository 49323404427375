.wrapper {
    display: flex;
    background: #F6F9FF;
}

.login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 50%;
    /*background-image: url("../../../assets/newImg/login_background.svg");*/
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease;
}

.login_wrapper_content_right {
    width: 50%;
}

.logo_block {
    position: absolute;
    top: 40px;
    left: 60px;
}

.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.sign_in_wrapper {
    padding-left: 112px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sign_in_form {
    border-radius: 24px;
    background: #FFF;
    padding: 121px 110px 32px 110px;
}

.sign_in_content {
    width: 315px;
}

.title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #2A2E37;
    margin-bottom: 64px;
    text-align: center;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
}

.my_input {
    width: 315px;
    height: 52px;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    border-radius: 8px;
    padding: 18px;
    box-sizing: border-box;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.my_input:focus {
    outline: 1.5px solid #979797;
}

.my_input:hover {
    outline: 1.5px solid #979797;
}

.input_block {
    margin-bottom: 16px;
}

.forgot {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
    letter-spacing: -0.24px;
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 16px;
}

.error {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #F01818;
    margin-top: 4px;
}

.btn_block {
    display: flex;
    justify-content: center;
}

.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 156px;
    height: 48px;
    box-sizing: border-box;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .3s all ease;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .3s all ease;
}

.dont_have_account {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;

    margin-bottom: 85px;
}

.link {
    color: #4DB887;
    text-align: center;
    font-family: Manrope, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-left: 8px;
    text-decoration: none;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #F01818;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.forget_credentials {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-top: 16px;
}

.forget_credentials a {
    margin-left: 5px;
    text-decoration: underline;
    color: #7DD9AF;
}

@media only screen
and (max-device-width: 1000px) {
    .sign_in_wrapper {
        padding-left: 0px;
        height: auto;
        max-width: 950px;
        margin-bottom: 56px;
    }

    
}

@media only screen
and (max-device-width: 768px) {

    .sign_in_wrapper {
        padding-left: 0px;
        height: auto;
    }

    .login_wrapper_content_left {
        /* position: relative;
        width: 100%;
        background-size: 140%;
        display: flex;
        justify-content: flex-start;
        transition: .3s all ease;
        flex-direction: column-reverse; */
    }

    .sign_in_form {
        width: 100%;
        height: auto;
        padding: 40px 16px 16px 16px;
        border-radius: 8px;
    }

    .sign_in_content {
        width: 100%;
    }

    .my_input {
        font-family: 'Century Gothic', serif;
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        padding: 14px 12px;
        box-sizing: border-box;
        outline: none;
        border: none;
        margin-top: 20px;
    }

    .subtitle {
        margin-bottom: 40px;
    }

    .logo_block {
        position: absolute;
        top: 20px;
        left: 16px;
    }

    .title {
        text-align: center;
        font-family: Manrope;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; 
        letter-spacing: -0.52px;
        margin-bottom: 40px;
    }

    .forget_credentials {
        text-align: center;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
    }

    .forget_credentials a {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        text-decoration-line: underline;
        margin-left: 3px;
    }

    .dont_have_account {
        margin-top: 40px;
        margin-bottom: 24px;
    }

    .link {
        text-align: center;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        letter-spacing: -0.28px;
        margin-left: 4px;
    }
}
