.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.btn_block {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 32px;
}

.btn_wrapper {
    width: 315px;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #F01818;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.error_wrapper {
    width: 315px;
}

.form_wrapper {
    border-radius: 24px;
    background: #FFF;
    padding: 140px 110px 32px 110px;
}

.forget_credentials {
    color: #B5B5B5;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    margin-top: 16px;
    width: 338px;
}

.forget_credentials a {
    margin-left: 5px;
    text-decoration: underline;
}

.back {
    text-align: center;
    margin-top: 64px;

    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.32px;
    margin-bottom: 108px;
}

.link {
    color: #4DB887;
    cursor: pointer;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        height: auto;
        margin-bottom: 56px;
    }
    .form_wrapper {
        padding: 40px 16px 16px 16px;
        border-radius: 8px;
    }
    .back {
        margin-top: 40px;
        margin-bottom: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        letter-spacing: -0.28px;
    }

    .back .link {
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; 
        letter-spacing: -0.28px;
        padding-left: 2px;
    }
}
