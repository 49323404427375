.tableWrapper p {
    margin: 0;
    padding: 0;
}

.tableWrapper {
    padding: 40px 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 69px;
}

.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    box-sizing: border-box;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.balance {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    gap: 20px;
}

.statementTable thead > tr > th {
    color: #B5B5B5 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: capitalize;
    border: none !important;

    font-family: Manrope, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.24px;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 6px 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
    word-break: break-all;
}

.statementTable tbody > tr > td:first-child {
    padding-left: 32px !important;
}
.statementTable tbody > tr > td:last-child {
    padding-right: 32px !important ;
}
.statementTable thead > tr > th:last-child {
    padding-right: 32px !important ;
}
.statementTable thead > tr > th:first-child {
    padding-left: 32px !important;
}

.statementTable tbody > tr > td:first-child
{
   padding-bottom: 6px !important;
}

.open {
    transition: all .3s ease-in-out;
    transform: rotate(180deg);
}

.close {
    transition: all .3s ease-in-out;
    transform: rotate(0);
}

.table_row_dark {
    background-color: #EEEFEF !important;
}

.timeCellP {
    color: #B5B5B5;
}

.out {
    color: #F01818;
}

.in {
    color: #74C69D;
}

.transaction_type {
    text-transform: capitalize;
}

.amountColumn > div:first-child {
    justify-content: flex-end !important;
    width: 100% !important;
}

.summaryWrapper {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding: 0 32px;
}

.summaryLine {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #2A2E37;
    padding: 22px 0;
    border-top: 1px solid #F6F9FF;;
}

.summaryValue{
    min-width: 132px;
    font-weight: 400;
    text-align: right;
}

.sum {
    font-weight: 600;
}

.line {
    font-family: Manrope, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    color: #282828;
}

.blockTitle span {
    text-transform: capitalize!important;
}

.rejected {
    color: #F01818;
    text-transform: capitalize;
}

.reason {
    color: #F01818;
    text-align: right;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: initial;
}

.pending {
    text-transform: capitalize;
    color: #282828;
}

.confirmed {
    text-transform: capitalize;
    color: #7DD9AF;
}

.recipient_row {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media only screen
and (max-device-width: 768px) {
    .tableWrapper p {
        margin: initial;
        padding: initial;
    }

    .transactionsListMobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .transfer {
        padding: 32px 16px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    }

    .tableWrapper {
        flex-direction: column-reverse;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
    }

    .headerWrapper {
        margin-top: 24px;
        border-radius: 12px;
        border: 1.5px solid #EEEFEF;
        padding: 32px 16px;
    }

    .mobileTransferRow {
        display: flex;
        align-items: flex-start;
        gap: 32px;
        margin-bottom: 32px;
        justify-content: start;
    }

    .blockTitle {
        text-align: start;
        margin-bottom: 8px;
    }

    .blockTitle span {
        text-align: start;
        text-transform: capitalize;

    }

    .headerWrapper {
        margin-bottom: 24px;
    }

    .transactionContentWrapper {
        text-align: right;
        font-family: Manrope;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        color: #B5B5B5;
    }

    .arrowList {
        margin-left: 8px;
        border: solid #B5B5B5;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-135deg);
    }
    
    .arrowDown {
        transform: rotate(45deg);
    }
}
