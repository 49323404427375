.body {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
}

.inactive {
    width: 12px;
    height: 3px;
    background-color: #E7E7E7;
    border-radius: 3px;
}

.active {
    width: 12px;
    height: 3px;
    background-color: #7DD9AF;
    border-radius: 3px;
}

@media only screen
and (max-device-width: 768px) {
    .body {
        gap: 5px;
    }
    
    .inactive {
        width: 8px;
        height: 2px;
        border-radius: 2px;
    }
    
    .active {
        width: 8px;
        height: 2px;
        border-radius: 2px;
    }
}