.cookie_popup {
    display: flex;
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 0px;
    left: 0;
    z-index: 999999999;
  }
  
  .cookie_body {
    width: 100%;
    background-color: #FFF;
    margin: 24px 80px;
    padding: 24px 40px;
    box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.18);
    border-radius: 8px;
    position: relative;
  }
  
  .cookie_content {
    color: #313131;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.32px;
    max-width: 95%;
  }
  
  .cookie_content a {
    color: #4DB887;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
    text-decoration-line: underline;
  }
  
  .close_cookie {
    font-size: 24pt;
    font-weight: bold;
    width: 24px;
    height: 24px;
    color: #0E492E;
    cursor: pointer;
    
    position: absolute;
    top: 24px;
    right: 40px;
    
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  @media only screen
  and (max-width: 768px) {
    .cookie_body {
      margin: 10px 16px;
      padding: 40px 24px;
    }
    .cookie_content {
      font-family: Manrope;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      width: 100%;
    }
    .close_cookie {
        top: 16px;
        right: 16px;
    }
  }