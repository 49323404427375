.wrapper {
    background: #F4F4F4;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container {
    width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    margin: 0 auto;
}

.main_text_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 120px 0;
}

.main_title {
    color: #398361;
    text-align: center;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 74px;
    letter-spacing: -2.22px;
    margin-bottom: 56px;
}

.main_text {
    color: #282828;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: -0.52px;
    width: 879px;
}

.my_link {
    color: #4DB887;
    text-decoration: underline;
}

.provides_block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 72px;
    margin-bottom: 120px;
}

.provides_title {
    color: #282828;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
}

.provides_cards_block {
    display: flex;
    gap: 24px;
    width: 100%;
}

.left_card {
    border-radius: 32px;
    background: #C8DBD2;
    width: 100%;
    padding: 100px 65px;
    box-sizing: border-box;
    color: #282828;
    display: flex;
    flex-direction: column;
}

.right_card {
    border-radius: 32px;
    background: #E7F0EC;
    width: 100%;
    padding: 100px 65px;
    box-sizing: border-box;
    color: #282828;
    display: flex;
    flex-direction: column;
}

.card_title {
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 40px;
}

.card_text {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.4px;
    margin-bottom: 16px;
}

.card_footer {
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.4px;
}

.map_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: 0 80px 0 0;
    margin-bottom: 120px;
    position: relative;
}

.map_block_text {
    color: #282828;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.64px;
    margin-bottom: 72px;
    width: 506px;
    z-index: 1;
}

.map_background {
    position: absolute;
    background-image: url("../../../assets/img/dot_map.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 995px;
    height: 465px;
    left: -246px;
    z-index: 0;
    transform: scale(-1, 1)
}

.map_title {
    color: #282828;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.96px;
    margin-bottom: 56px;
}

.text_block {
    margin-bottom: 32px;
    width: 526px;
    display: flex;
    flex-direction: column;
}

.map_subtitle {
    color: #282828;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.52px;
    margin-bottom: 16px;
}

.map_text {
    color: #282828;
    text-align: justify;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.32px;
}
@media only screen
and (min-device-width: 320px)
and (max-device-width: 768px) {

    .main_text_block {
        margin: 0;
        margin-bottom: 120px;
    }

    
    .container {
        width: 100%;
        padding: 32px 16px 104px;
    }
    .main_title, .provides_title {
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0;
        margin-bottom: 20px;
    }
    .main_text {
        width: auto;
        font-size: 20px;
        padding: 0 11px;
    }
    .provides_block {
        gap: 22px;
        margin-bottom: 40px;
    }
    .provides_cards_block {
        flex-direction: column;
        gap: 20px;
    }
    .left_card, .right_card {
        padding: 30px 20px;
    }
    .card_title {
        width: 250px;
        margin: 0px auto 20px;
        font-size: 26px;
        line-height: 30px;
        color: #282828;
    }
    .card_text, .card_footer {
        color: #282828;
        font-weight: 400;
    }
    .map_block {
        padding: 0;
        margin-bottom: 220px;
    }
    .map_background {
        width: 100%;
        height: 100%;
        left: 20px;
        bottom: -88%;
        transform: scaleX(1);
    }
    .map_block_text {
        width: auto;
    }
    .map_title {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 30px;
        margin-left: 20px;
        text-align: start;
        text-decoration: underline #C8DBD2;
    }
    .text_block {
        align-items: flex-start;
        width: 90%;
        padding: 0 20px;
        margin-bottom: 20px;
    }
}