.logo_block {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.links_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #398361;
    margin-bottom: 20px;
    text-align: left;
}


.nav_item {
    display: flex;
    width: 100%;
    height: 24px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;
}

.services_block {
    margin-top: 16px;
}

.logout {
    position: absolute;
    bottom: 12px;
    left: 32px;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.logout:hover .img_logout {
    transition: .3s all ease;
    background: #7DD9AF;
}

.text {
    margin-left: 16px;
}

.logout:hover .text {
    transition: .3s all ease;
    color: #7DD9AF;
}

.logo {
    width: 155px;
    height: 58px;
}

@media only screen
and (max-device-width: 768px) {
    .links_title {
        color: #FFF;
        font-family: Manrope, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }
}
