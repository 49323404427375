.accountStatementWrapper {
    display: flex;
    flex-direction: column;
    gap: 40px
}

.tabstitle_block {
    width: fit-content;
    padding: 6px;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
}

.separator {
    width: 1.5px;
    height: 20px;
    background-color: #E7E7E7;
}

.tabtitle {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #B5B5B5;
    text-decoration: none;
    cursor: pointer;
    transition: .3s all ease;
    margin: 0 20px!important;
    text-align: center;
    box-shadow: none;
}

.tabtitle:hover {
    color: #666666;
    transition: .3s all ease;
}

.active_tab {
    color: #0E492E;
    outline: none;
    text-decoration: underline #0E492E;
}

@media only screen
and (max-device-width: 768px) {
    .accountStatementWrapper {
        gap: 32px
    }

    .tabstitle_block {
        padding: 0 6px;
        display: flex;
        list-style-type: none;
        margin-bottom: 32px;
        width: 100%;
    }

    .tabtitle {
        width: 50%;
        margin: 0 10px!important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
    }

    .separator {
        display: none;
    }
}
