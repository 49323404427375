.wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    padding: 40px 32px;
    box-sizing: border-box;
    margin-bottom: 32px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.06);
}

.accounts_list_block {
    margin-bottom: 40px;
}

.list_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 24px;
}

.accounts_list {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
}

.accounts_list_header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.list_block {
    width: 100%;
}

.list_block_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
}

.list_block_value {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    width: 100%;
    text-transform: capitalize;
    margin-top: 8px;
}

.change_password_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 16px;
}

.change_password_subtitle {
    color: #B5B5B5;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
}

.input_block {
    width: 316px;
    margin-bottom: 16px;
    position: relative;
}

.input_label {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.button_wrapper {
    display: flex;
    margin-top: 16px;
    justify-content: flex-end;
}

.email_block {
    margin-top: 40px;
}

.need_to_verify {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2046A1;
    cursor: pointer;
    transition: .3s all ease
}

.need_to_verify:hover {
    color: #89C0E9;
    transition: .3s all ease
}

.need_to_verify path {
    fill: #2046A1;
    transition: .5s all ease
}

.need_to_verify:hover path {
    fill: #89C0E9;
    transition: .5s all ease
}

.set_as_main {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FEC979;
    cursor: pointer;
    transition: .5s all ease;
}

.tick_svg {
    width: 24px;
    height: 24px;
    background-color: #FEC979;
    transition: .5s all ease;
    mask-image: url("../../../../../assets/img/personalAccount/tick.svg");
}

.set_as_main:hover {
    color: #FFBB54;
    transition: .5s all ease;
}

.set_as_main:hover .tick_svg {
    background-color: #FFBB54;
    transition: .5s all ease;
}

.delete_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../assets/img/personalAccount/delete.svg");
    background-color: #FF8A94;
    margin-right: 6px;
    transition: .5s all ease;
}

.action {
    cursor: pointer;
    color: #FFA724;
    text-align: center;
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
    transition: .5s all ease;
}

.action:hover {
    color: #FFC774;
    transition: .5s all ease;
}

.action svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.action path {
    fill: #FFA724;
    transition: .5s all ease;
}

.action:hover path {
    fill: #FFC774;
    transition: .5s all ease;
}

.delete_text_active {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FF6471;
    transition: .5s all ease;
    margin-left: 4px;
}

.flex {
    display: flex;
    align-items: center;
}

.action:hover .delete_text_active {
    color: #FF8A94;
    transition: .5s all ease;
}

.field_error {
    position: absolute;
    font-family: Manrope, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    bottom: -18px;
    color: #F01818;
}


.modal_title {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    width: 180px;
}

.btn_block {
    margin-top: 40px;
}

.mobile_email_block {
    display: none;
}

.disabled {
    color: #979797;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    gap: 6px;
}

.disabled path {
    fill: #979797
}

.disabled .tick_svg{
    background-color: #979797;
}

.yellow {
    color: #FFA724;
    font-family: Manrope, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 18px;
    letter-spacing: -0.28px;
    margin-bottom: 16px;
}

.personal_information_block {
    width: 100%;
    border-bottom: 1px solid #E7E7E7;
    margin-bottom: 16px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row_padding {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
}

.information_title {
    color: #B5B5B5;
    font-family: Manrope, serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
}

.user {
    margin-top: 8px;
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

.contact_information_value {
    color: #282828;
    font-family: Manrope, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.28px;
}

@media only screen
and (max-device-width: 768px) {

    .wrapper {
        width: 100%;
        padding: 0;
        background: transparent;
    }

    .accounts_list_block {
        padding: 32px 16px 48px 16px;
        background-color: #fff;
        margin-bottom: 0;
        border-radius: 12px 12px 0 0;
    }

    .form {
        background-color: #fff;
        border-radius: 0 0 12px 12px;
        padding: 32px 16px 32px 16px;
    }

    .mobile_email_block {
        border-radius: 8px;
        background: #FFF;
        padding: 16px;
    }

    .list_block_value {
        height: auto;
        text-align: start;
    }

    .mobile_email {
        width: 100%;
    }

    .accounts_list {
        justify-content: space-between;
        border-bottom: none;
    }

    .button_block {
        margin-top: 32px;
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    .list_block {
        width: 100%;
    }

    .email_block {
        margin-top: 0;
        margin-bottom: 48px;
        padding: 32px 16px 48px 16px;
        background-color: #fff;
        border-radius:  0 0 12px 12px;
    }
}
